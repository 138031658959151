import React from "react";
import {useLocale} from "../../context/locale";

const OverView = ({ advert }) => {
  const { messages } = useLocale();
  const overviewData = [
    {
      icon: "flaticon-bed",
      label: messages?.properties?.bedrooms,
      value: advert?.number_of_beds,
    },
    {
      icon: "flaticon-shower",
      label: messages?.properties?.bathrooms,
      value: advert?.number_of_bathrooms,
    },
    {
      icon: "flaticon-event",
      label: messages?.properties?.year_built,
      value: advert?.building_age,
    },
    {
      icon: "flaticon-garage",
      label: messages?.properties?.garage,
      value: advert?.carpark,
      xs: true,
    },
    {
      icon: "flaticon-expand",
      label: messages?.properties?.sqft,
      value: advert?.square_meters,
      xs: true,
    },
    {
      icon: "flaticon-home-1",
      label: messages?.properties?.property_type,
      value: advert?.property_type?.name,
    },
  ];


  return (
    <>
      {overviewData?.map((item, index) => (
        <div
          key={index}
          className={`col-sm-6 col-lg-4 ${item.xs ? "mb25-xs" : "mb25"}`}
        >
          <div className="overview-element d-flex align-items-center">
            <span className={`icon ${item.icon}`} />
            <div className="ml15">
              <h6 className="mb-0">{item.label}</h6>
              <p className="text mb-0 fz15">{item.value}</p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default OverView;
