import React, { useState, useEffect } from "react";
import { useLocale } from "../../context/locale";

const BlogFilter = ({ blogs, categoryList, category, updateCategory }) => {
  const { locale } = useLocale()

  return (
      <>
          <ul className="nav nav-pills mb20">
                <li className="nav-item" role="presentation">
                    <button
                        onClick={() => updateCategory(null)}
                        className={`nav-link mb-2 mb-lg-0 mt-3 fw500 dark-color ${
                            category === null ? "active" : ""
                          }`}
                    >
                        All
                    </button>
                </li>
              {categoryList?.map((subCategory, index) => (
                  <li className="nav-item" role="presentation" key={index}>
                      <button
                          onClick={() => updateCategory(subCategory.id)}
                          className={`nav-link mb-2 mb-lg-0 mt-3 fw500 dark-color ${
                            subCategory.id === category ? "active" : ""
                          }`}
                      >
                          {subCategory?.name}
                      </button>
                  </li>
              ))}
          </ul>
          <div className="row">
              {blogs?.map((blog) => (
                  <div className="col-sm-6 col-lg-4" key={blog.id}>
                      <div className="blog-style1">
                          <div className="blog-img">
                              <img

                                  className="w-100 h-100 cover"
                                  src={blog?.image}
                                  alt={blog?.tags}
                                  loading="lazy"
                              />
                          </div>
                          <div className="blog-content">
                              <h6 className="title mt-1">
                                  <a href={`${locale != 'en' ? '/'+locale : ''}/${blog?.category?.url}/${blog.link}`}>{blog.title}</a>
                              </h6>
                          </div>
                      </div>
                  </div>
              ))}
          </div>
      </>
  );
};

export default BlogFilter;
