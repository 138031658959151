import { Link } from "react-router-dom";
import ContactMeta from "./ContactMeta";
import MenuWidget from "./MenuWidget";
import Copyright from "./Copyright";
import { useState } from "react";
import {useLocale} from "../../context/locale";

const Footer = ({ settings }) => {

  const [ email, setEmail ] = useState('')
  const [ responseMessage, setResponseMessage ] = useState(null)
  const { messages } = useLocale();

  const subscribers = async(e) => {
    e.preventDefault()
    try {
      const response = await axios.post('/api/subscribers', {
          email: email,
      });

      if (response.data.success) {
          setResponseMessage(response.data.success);
      }

      if (response.data.error) {
          setResponseMessage(response.data.error);
      }

    } catch (error) {
        setResponseMessage(error.response.data.error);
    }
  }

  return (
    <>
      <div className="container">
        <div className="row gray-bdrb1 pb30 mb60">
          <div className="col-sm-5">
            <div className="footer-widget text-center text-sm-start">
              <Link className="footer-logo" to="/">
                <img

                  className="mb40"
                  src={settings?.logo}
                  alt={settings?.title}
                />
              </Link>
            </div>
          </div>

          <div className="col-sm-7">
            <div className="social-widget text-center text-sm-end">
              <div className="social-style1  light-style2">
                <a className="me-2 fw600 fz15" href="#">
                    {messages?.footer?.follow_us}
                </a>
                <a href={settings?.facebook}>
                  <i className="fab fa-facebook-f list-inline-item"></i>
                </a>
                <a href={settings?.twitter}>
                  <i className="fab fa-twitter list-inline-item"></i>
                </a>
                <a href={settings?.instagram}>
                  <i className="fab fa-instagram list-inline-item"></i>
                </a>
                <a href={settings?.linkedin}>
                  <i className="fab fa-linkedin-in list-inline-item"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8">
            <div className="row justify-content-between">
              <MenuWidget />
            </div>
          </div>

          <div className="col-md-5 col-lg-4">
            <div className="footer-widget light-style mb-4 mb-lg-50">
              <ContactMeta settings={settings} />
              <div className="footer-widget light-style mb30">
                <div className="mailchimp-widget ">
                  <h6 className="title mb20">{messages?.footer?.keep_yourself_up_to_date}</h6>
                  <form onSubmit={subscribers} className="mailchimp-style1 white-version at-home9">
                    <input onChange={(e) => setEmail(e.target.value)} type="email" className="form-control" style={{color: '#111'}} placeholder={messages?.footer?.your_email} />
                    <button type="submit">Subscribe</button>
                  </form>
                  {responseMessage != "" && <h4 className="mb30 mt30 text-center">{responseMessage}</h4>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Copyright />
    </>
  );
};

export default Footer;
