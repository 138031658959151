import React from 'react'

export default function Details({ blog }) {
  return (
    <>
     <div className="container">
          <div className="row" data-aos="fade-up" data-aos-delay="100">
            <div className="col-lg-12">
              <h2 className="blog-title">
                {blog?.title}
              </h2>
            </div>
          </div>
        </div>

        <div
          className="mx-auto maxw1600 mt60"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div className="row">
            <div className="col-lg-12">
              <div className="large-thumb">
                <img
                  className="w-100 h-100 cover"
                  src={blog?.image}
                  alt={blog?.tags}
                />
              </div>
            </div>
          </div>
        </div>

    </>
  )
}
