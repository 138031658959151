import MainMenu from "../../components/MainMenu";
import SidebarPanel from "../../components/sidebar-panel";

import React, { useEffect, useState } from "react";
import CurrencySelector from "../CurrencySelector";
import LangSelector from "../LangSelector";

const DefaultHeader = ({ settings }) => {
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 10) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeBackground);
    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  }, []);

  return (
    <>
      <header
        className={`header-nav nav-homepage-style light-header menu-home4 main-menu ${
          navbar ? "sticky slideInDown animated" : ""
        }`}
      >
        <nav className="posr">
          <div className="container maxw1800 posr">
            <div className="row align-items-center justify-content-between">
              <div className="col-auto">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="logos mr40">
                    <a className="header-logo logo1" href="/">
                      <img
                        src={settings?.logo}
                        alt={settings?.company_name}
                      />
                    </a>
                    <a className="header-logo logo2" href="/">
                      <img
                        src={settings?.logo}
                        alt={settings?.company_name}
                      />
                    </a>
                  </div>

                  <MainMenu />
                </div>
              </div>

              <div className="col-auto">
                  <div className="d-flex align-items-center gap-1">
                      <div className="d-flex align-items-center gap-1">
                          <LangSelector/>
                          <CurrencySelector/>
                      </div>
                      <a
                          className="sidemenu-btn filter-btn-right"
                          href="#"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#SidebarPanel"
                          aria-controls="SidebarPanelLabel"
                      >
                          <img

                              className="img-1"
                              src="/images/dark-nav-icon.svg"
                              alt={settings?.company_name}
                          />
                          <img

                              className="img-2"
                              src="/images/dark-nav-icon.svg"
                              alt={settings?.company_name}
                          />
                      </a>
                  </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
        <div
            className="offcanvas offcanvas-end"
            tabIndex="-1"
            id="SidebarPanel"
            aria-labelledby="SidebarPanelLabel"
        >
        <SidebarPanel settings={settings} />
      </div>
    </>
  );
};

export default DefaultHeader;
