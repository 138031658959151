

const YearBuilt = ({ filterFunctions }) => {
  return (
    <div className="space-area">
      <div className="d-flex align-items-center justify-content-between">
        <div className="form-style1">
          <input
            type="number"
            className="form-control filterInput"
            placeholder={2019}
            id="minFeet2"
          />
        </div>
        <span className="dark-color">-</span>
        <div className="form-style1">
          <input
            type="number"
            className="form-control filterInput"
            placeholder={2022}
            id="maxFeet2"
          />
        </div>
      </div>
    </div>
  );
};

export default YearBuilt;
