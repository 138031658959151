import DefaultHeader from "../../components/header/DefaultHeader";
import Footer from "../../components/footer";
import MobileMenu from "../../components/mobile-menu";
import MetaData from "../../components/MetaData";
import { useEffect, useState } from "react";

const Faq = ({ settings, content }) => {
  const metaInformation = {
      title: settings?.company_name + " | " + content?.title,
  };

  const [ faqs, setFaqs ] = useState(null)

  useEffect(() => {
    const getFaqs = async() => {
      try {
        const response = await axios.get('/api/faqs')
        setFaqs(response.data.faqs)
      }
      catch(error) {
        console.log(error)
      }
    }
    getFaqs()
  },[])

  return (
    <>
    <MetaData meta={metaInformation} />
      <DefaultHeader settings={settings} />

      <MobileMenu settings={settings} />

      <section className="breadcumb-section2 p-0" style={{ backgroundImage: `url(${content?.image})` }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcumb-style1">
                <h2 className="title">{content?.title}</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our-faq pb90 pt-0">
        <div className="container">
          <div className="row wow fadeInUp" data-wow-delay="300ms">
            <div className="col-lg-12">
              <div className="ui-content">
                <h4 className="title">Question About Selling</h4>
                <div className="accordion-style1 faq-page mb-4 mb-lg-5">
                  <div className="accordion" id="accordionExample">
                      {faqs?.map((item, index) => (
                        <div className="accordion-item" key={index}>
                          <h2 className="accordion-header" id={item.id}>
                            <button
                              className={`accordion-button ${index === 2 ? "" : "collapsed"}`}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapse${index + 1}`}
                              aria-expanded={index === 2 ? "true" : "false"}
                              aria-controls={`collapse${index + 1}`}
                            >
                              {item.question}
                            </button>
                          </h2>
                          <div
                            id={`collapse${index + 1}`}
                            className={`accordion-collapse collapse ${
                              index === 0 ? "show" : ""
                            }`}
                            aria-labelledby={item.id}
                            data-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>{item.reply}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="footer-style1 at-home4 pt60 pb-0">
        <Footer settings={settings} />
      </section>
    </>
  );
};

export default Faq;
