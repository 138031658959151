import { useEffect, useState } from "react";
import Cookies from 'js-cookie';

export default function Compare() {
    const [isValid, setIsValid] = useState([]);

    function getFirstPath() 
    {
        const parsedUrl = new URL(window.location.href);
        const pathSegments = parsedUrl.pathname.split('/').filter(Boolean);
        return pathSegments[0] || null; 
    }

    useEffect(() => {
        if(getFirstPath() != 'compare') {
            const compareIds = Cookies.get('compare'); 
        
            if (compareIds) {
                const parsedIds = JSON.parse(compareIds);
                const getAdverts = async() => {
                    try {
                        const response = await axios.post('/api/compare/list', {
                            adverts: parsedIds
                        })
                        if(response.data.adverts) {
                            setIsValid(response.data.adverts)
                        }
                    }
                    catch(error) {
                        console.log(error)
                    }
                }
                getAdverts()

            } 
            else {
                setIsValid(false);
            }
        }
    }, []);

    const deleteCompare = () => {
        Cookies.remove('compare');
        window.location.reload()
    }

    return (
        <>
        {isValid.length > 0  ?
            <div className="comparison-modal">
                <div className="modal-content">
                    <div className="row">
                        {isValid?.map((value, index) => 
                            <div className="col-4 col-md-4 text-center mb-4" key={index}>
                                <img src={value.image} alt={value.description_seo} className="img-fluid" />
                                <p>{value.title}</p>
                            </div>
                        )}
                    </div>
                    <div className="row">
                        <div className="col-6 col-md-6 text-center">
                            <a href="/compare" className="compare-link">Compare</a>
                        </div>
                        <div className="col-6 col-md-6 text-center">
                            <a onClick={() => deleteCompare()} className="compare-button">Clear</a>
                        </div>
                    </div>
                </div>
            </div>
        : ''}
        </>
    )
}