import React, { useState } from "react";
import axios from "axios";
import SingleAgentInfo from "./SingleAgentInfo";
import {useLocale} from "../../../context/locale";

const InfoWithForm = ({ settings }) => {
  const { messages } = useLocale();
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
    url: window.location.href
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('/api/advert-message', formData)
      if(response.data.error) {
        alert(response.data.error)
      }
      if(response.data.success) {
        alert(response.data.success)
      }
    } catch (error) {
      console.error(error)
    }
    setFormData({
      name: '',
      phone: '',
      email: '',
      message: '',
      url: window.location.href
    })
  }

  return (
    <>
      <SingleAgentInfo settings={settings} />

      <div className="row">
        <div className="col-md-12">
          <form className="form-style1 row" onSubmit={handleSubmit}>
            <div className="col-md-6">
              <div className="mb20">
                <label className="heading-color ff-heading fw600 mb10">
                    {messages?.properties?.name}
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder={messages?.properties?.name}
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="mb20">
                <label className="heading-color ff-heading fw600 mb10">
                    {messages?.properties?.phone}
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="phone"
                  placeholder={messages?.properties?.phone}
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="mb20">
                <label className="heading-color ff-heading fw600 mb10">
                    {messages?.properties?.email}
                </label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder={messages?.properties?.email}
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="mb10">
                <label className="heading-color ff-heading fw600 mb10">
                    {messages?.properties?.message}
                </label>
                <textarea
                  cols={30}
                  rows={4}
                  name="message"
                  placeholder={messages?.properties?.message}
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="checkbox-style1 d-block d-sm-flex align-items-center justify-content-between mb10">
              <label className="custom_checkbox fz14 ff-heading">
                  {messages?.properties?.terms}
                <input type="checkbox" required />
                <span className="checkmark" />
              </label>
            </div>

            <div className="btn-area mt20">
              <button type="submit" className="ud-btn btn-white2">
                  {messages?.properties?.request_information} <i className="fal fa-arrow-right-long" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default InfoWithForm;
