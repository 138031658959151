import React, { useEffect, useState } from "react";
import {useLocale} from "../context/locale";

const ApartmentTypes = () => {
  const { messages, locale } = useLocale();
  const [ subCategory, setSubCategory ] = useState([]);
  const [ megaCategory, setMegaCategory ] = useState([]);

  useEffect(() => {
    const getExplore = async () => {
      try {
        const response = await axios.post('/api/explore', {
            locale: locale
        });
        setSubCategory(response.data.subcategory);
        setMegaCategory(response.data.megacategory);
      } catch (error) {
        console.log(error);
      }
    };
    getExplore();
  }, []);

  const combinedCategories = [...subCategory, ...megaCategory];

  return (
    <>
      {combinedCategories?.map((apartment, index) => (
        <div key={index} className={`${index == 0 || index == 5 || index == 8 || index == 13 || index == 16 ? 'col-lg-6' : 'col-6 col-lg-3'}`}>
          <div className="feature-style1 mb30">
            <div className="feature-img">
              <img
                className="w-100 cover"
                style={{ height: '300px' }}
                src={apartment.image}
                alt={apartment.name}
              />
            </div>
            <div className="feature-content">
              <div className="top-area">
                <h6 className="title mb-1">{apartment.city}</h6>
                <p className="text">{apartment.name}</p>
              </div>
              <div className="bottom-area">
                <a
                  className="ud-btn2"
                  href={`${locale != 'en' ? '/'+locale : ''}/${apartment.category_url}/${apartment.subcategory_url ? apartment.subcategory_url + '/' : ''}${apartment.url}`}
                >
                  See All Cities
                  <i className="fal fa-arrow-right-long" />
                </a>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ApartmentTypes;
