import React, { useState, useEffect } from "react";
import Select from "react-select";

const PropertyType = ({ propertyType, setPropertyType }) => {
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    const getProperties = async () => {
      try {
        const response = await axios.get('/api/properties');
        const data = response.data.properties;
  
        setProperties(data.map((value) => ({ label: value.name, value: value.name })));
      } catch (error) {
        console.log(error);
      }
    }
    
    getProperties();
  }, []);

  const customStyles = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      backgroundColor: isSelected
        ? "#eb6753"
        : isHovered || isFocused
        ? "#eb675312"
        : undefined,
    }),
  };

  return (
    <Select
      value={Array.isArray(propertyType) && propertyType.length > 0 
        ? propertyType.map(type => ({
            value: type,
            label: properties.find(property => property.value === type)?.label || type,
          }))
        : null}
      name="propertyType"
      styles={customStyles}
      options={properties}
      className="select-custom filterSelect"
      classNamePrefix="select"
      onChange={(selectedOptions) => {
        const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setPropertyType(values);
      }}
      isMulti
      required
    />
  );
};

export default PropertyType;
