import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import Modal from "react-modal";
import Cookies from 'js-cookie';
import {useLocale} from "../context/locale";

const FeaturedListings = ({ id, slideid }) => {
  const { messages, locale } = useLocale()
  const [ data, setData ] = useState([])
  const [ modalIsOpen, setModalIsOpen ] = useState(false)
  const [ currentListing, setCurrentListing ] = useState(null)
  useEffect(() => {
    const getData = async() => {
      const response = await axios.post('/api/type', {
        id: id,
        locale: locale
      })
      setData(response.data)
    }
    getData()
  },[])

  let lang = '';

  if (locale != 'en') {
    lang = '/' + locale;
  }

  const openModal = (listing) => {
    setCurrentListing(listing);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const compare = async(id) => {
    try {
      const response = await axios.post('/api/compare/add', { id });

      if (response.data.success && response.data.id) {
        const existingItems = Cookies.get('compare');
        const itemsArray = existingItems ? JSON.parse(existingItems) : [];

        if (!itemsArray.includes(response.data.id)) {
          if (itemsArray.length < 3) {
            itemsArray.push(response.data.id);
            Cookies.set('compare', JSON.stringify(itemsArray), { expires: 7 });
            window.location.reload()
          }
        }
      }
      else {

      }
    } catch (error) {
      console.error('Error in compare function:', error);
    }
  }

  return (
    <>
      <Swiper
        spaceBetween={30}
        modules={[Navigation, Pagination]}
        navigation={{
          nextEl: ".featured-next__active_" + slideid,
          prevEl: ".featured-prev__active_" + slideid,
        }}
        pagination={{
          el: ".featured-pagination__active_" + slideid,
          clickable: true,
        }}
        slidesPerView={1}
        breakpoints={{
          300: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 3,
          },
        }}
      >
        {data?.slice(0, 4).map((listing) => (
          <SwiperSlide key={listing.id}>
            <div className="item">
              <div className="listing-style1">
                <div className="list-thumb">
                  <img
                    className="w-100 cover"
                    style={{height:'230px'}}
                    src={listing.image}
                    alt={listing.name}
                  />

                  <div className="list-price">
                    {listing.formatted_converted_price}
                  </div>
                </div>
                <div className="list-content">
                  <h6 className="list-title">
                      <a href={`${lang}/properties/${listing.link}`}>{listing.title}</a>
                  </h6>
                  <p className="list-text">
                      {listing.region != null && listing.region?.title + " / "}
                      {listing.district != null && listing.district?.title + " / "}
                      {listing.city != null && listing.city?.title + " / "}
                      {listing.country != null && listing.country?.title}
                  </p>
                  <div className="list-meta d-flex align-items-center">
                    <a href="#">
                      <span className="flaticon-bed" /> {listing.number_of_beds != null && listing.number_of_beds} {messages?.properties?.bed}
                    </a>
                    <a href="#">
                      <span className="flaticon-shower" /> {listing.number_of_beds != null && listing.number_of_bathrooms} {messages?.properties?.bath}
                    </a>
                    <a href="#">
                      <span className="flaticon-expand" /> {listing.number_of_beds != null && listing.square_meters} {messages?.properties?.sqft}
                    </a>
                  </div>
                  <hr className="mt-2 mb-2" />
                  <div className="list-meta2 d-flex justify-content-between align-items-center">
                    <span className="for-what">{listing.real_estate_status}</span>
                    <div className="icons d-flex align-items-center">
                      <a href={`${lang}/properties/${listing.link}`}>
                        <span className="flaticon-fullscreen" />
                      </a>
                      <a onClick={() => compare(listing.id)}>
                        <span className="flaticon-new-tab" />
                      </a>
                      <a onClick={() => openModal(listing)}>
                        <span className="flaticon-share-1" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {currentListing && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Share Modal"
          ariaHideApp={false}
          style={{
            overlay: {
              zIndex: 1000,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              width: '40%',
              padding: '30px',
              position: 'relative',
            },
          }}
        >
          <button onClick={closeModal} style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'none',
            border: 'none',
            fontSize: '18px',
            cursor: 'pointer',
          }}>
            ✖
          </button>
          <div className="share-box">
            <div className="social-items">
              <div className="social-item">
                <a href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="Facebook">
                  <i className="fa-brands fa-facebook-f"></i>
                </a>
              </div>
              <div className="social-item">
                <a href={`https://twitter.com/intent/tweet?url=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="Twitter X">
                  <i className="fa-brands fa-twitter"></i>
                </a>
              </div>
              <div className="social-item">
                <a href={`https://www.linkedin.com/shareArticle?url=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="LinkedIn">
                  <i className="fa-brands fa-linkedin-in"></i>
                </a>
              </div>
              <div className="social-item">
                <a href={`https://pinterest.com/pin/create/button/?url=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="Pinterest">
                  <i className="fa-brands fa-pinterest-p"></i>
                </a>
              </div>
              <div className="social-item">
                <a href={`whatsapp://send?text=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="WhatsApp">
                  <i className="fa-brands fa-whatsapp"></i>
                </a>
              </div>
              <div className="social-item">
                <a href={`https://www.reddit.com/submit?url=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="Reddit">
                  <i className="fa-brands fa-reddit"></i>
                </a>
              </div>
              <div className="social-item">
                <a href={`https://t.me/share/url?url=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="Telegram">
                  <i className="fa-brands fa-telegram"></i>
                </a>
              </div>
              <div className="social-item">
                <a href={`https://www.tumblr.com/widgets/share/tool/preview?url=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="Tumblr">
                  <i className="fa-brands fa-tumblr"></i>
                </a>
              </div>
              <div className="social-item">
                <a href={`mailto:?body=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="Email">
                  <i className="fa-regular fa-envelope"></i>
                </a>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default FeaturedListings;
