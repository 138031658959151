import DefaultHeader from "../../components/header/DefaultHeader";
import Footer from "../../components/footer";
import MobileMenu from "../../components/mobile-menu";
import { Link } from "react-router-dom";
import React from "react";
import MetaData from "../../components/MetaData";

const NotFound = ({ category, settings }) => {

  const metaInformation = {
    title: settings?.title + ' | Not Found',
  };

  return (
    <>
    <MetaData meta={metaInformation} />
      <DefaultHeader category={category} />

      <MobileMenu category={category} />

      <section className="our-error">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6" data-aos="fade-left">
              <div className="animate_content text-center text-xl-start">
                <div className="animate_thumb">
                  <img
                  
                    className="w-100 h-100 cover"
                    src="/images/icon/error-page-img.svg"
                    alt="error-page-img"
                  />
                </div>
              </div>
            </div>

            <div
              className="col-xl-5 offset-xl-1 wow fadeInLeft"
              data-aos="fade-right"
            >
              <div className="error_page_content mt80 mt50-lg text-center text-xl-start">
                <div className="erro_code">
                  <span className="text-thm">40</span>4
                </div>
                <div className="h2 error_title">
                  Oops! It looks like you&apos;re lost.
                </div>
                <p className="text fz15 mb20">
                  The page you&apos;re looking for isn&apos;t available. Try to
                  search again <br className="d-none d-lg-block" /> or use the
                  go to.
                </p>
                <Link to="/" className="ud-btn btn-dark">
                  Go Back To Homepages
                  <i className="fal fa-arrow-right-long" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="footer-style1 pt60 pb-0">
        <Footer />
      </section>
    </>
  );
};

export default NotFound;
