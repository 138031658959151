import { useEffect, useState } from "react";

const SidebarPanel = ({ settings }) => {

  const [ sidepanel, setSidepanel ] = useState(null)

  useEffect(() => {
    const getSidepanel = async() => {
      try {
        const response = await axios.get('/api/sidebar')

        setSidepanel(response.data.sidebar)
      }
      catch(error) {
        console.log(error)
      }
    }
    getSidepanel()
  }, [])

  return (
    <div className="rightside-hidden-bar">
      <div className="hsidebar-header">
        <div
          className="sidebar-close-icon"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          <span className="far fa-times"></span>
        </div>
        <h4 className="title">{settings?.company_name}</h4>
      </div>

      <div className="hsidebar-content">
        <div className="hiddenbar_navbar_content">
          <div className="hiddenbar_navbar_menu">
            <ul className="navbar-nav">
              {sidepanel?.map((item) => (
                <li className="nav-item" key={item.id}>
                  {item?.category != null && item?.subcategory == null && item?.megacategory == null ? 
                    <a className="nav-link" href={"/" + item.category?.url} role="button">
                      {item.category?.name}
                    </a>
                  : ''}
                  
                  {item?.category != null && item?.subcategory != null && item?.megacategory == null ? 
                    <a className="nav-link" href={"/" + item.category?.url + "/" + item.subcategory?.url} role="button">
                      {item.subcategory?.name}
                    </a>
                  : ''}

                  {item?.category != null && item?.subcategory != null && item?.megacategory != null ? 
                    <a className="nav-link" href={"/" + item.category?.url + "/" + item.subcategory?.url + "/" + item.megacategory?.url} role="button">
                      {item.megacategory?.name}
                    </a>
                  : ''}

                  {item?.private == 1 ? 
                    <a className="nav-link" href={"/" + item.content?.url} role="button">
                      {item.content?.title}
                    </a>
                  : ''}   
                </li>
              ))}
            </ul>
          </div>

          <div className="hiddenbar_footer position-relative bdrt1">
            <div className="row pt45 pb30 pl30">
              <div className="col-auto">
                <div className="contact-info">
                  <p className="info-title dark-color">Customer Care</p>
                    <h6 className="info-phone dark-color">
                      <a href={'tel:'+settings?.phone}>{settings?.phone}</a>
                    </h6>
                </div>
              </div>
              <div className="col-auto">
                <div className="contact-info">
                  <p className="info-title dark-color">Need Support?</p>
                    <h6 className="info-mail dark-color">
                      <a href={'mailto:'+settings?.email}>
                        {settings?.email}
                      </a>
                    </h6>
                </div>
              </div>
            </div>
            <div className="row pt30 pb30 bdrt1">
              <div className="col-auto">
                <div className="social-style-sidebar d-flex align-items-center pl30">
                  <h6 className="me-4 mb-0">Social</h6>
                    <a className="me-3" href={settings?.facebook}>
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a className="me-3" href={settings?.twitter}>
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a className="me-3" href={settings?.instagram}>
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a className="me-3" href={settings?.linkedin}>
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarPanel;
