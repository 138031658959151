import React from "react";
import Modal from "react-modal";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import {useLocale} from "../../context/locale";

const PropertyHeader = ({ advert }) => {
  const [ modalIsOpen, setModalIsOpen ] = useState(false)
  const [ currentListing, setCurrentListing ] = useState(null)
  const { messages, locale } = useLocale();

  const handlePrint = () => {
    window.print();
  }
  const openModal = (listing) => {
    setCurrentListing(listing);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  let lang = '';

  if (locale != 'en') {
    lang = '/' + locale;
  }

  const compare = async(id) => {
    try {
      const response = await axios.post('/api/compare/add', { id });

      if (response.data.success && response.data.id) {
        const existingItems = Cookies.get('compare');
        const itemsArray = existingItems ? JSON.parse(existingItems) : [];

        if (!itemsArray.includes(response.data.id)) {
          if (itemsArray.length < 3) {
            itemsArray.push(response.data.id);
            Cookies.set('compare', JSON.stringify(itemsArray), { expires: 7 });
            window.location.reload()
          }
        }
      }
      else {

      }
    } catch (error) {
      console.error('Error in compare function:', error);
    }
  }

  return (
    <>
      <div className="col-lg-8">
        <div className="single-property-content mb30-md">
          <h2 className="sp-lg-title">{advert?.title}</h2>
          <div className="pd-meta mb15 d-md-flex align-items-center">
            <p className="text fz15 mb-0 bdrr1 pr10 bdrrn-sm">
                {messages?.properties?.locations}
            </p>
            <a
              className="ff-heading text-thm fz15 bdrr1 pr10 ml0-sm ml10 bdrrn-sm"
              href="#"
            >
              <i className="fas fa-circle fz10 pe-2" />
                {advert?.region?.title != null && advert?.region?.title +' / '}
                {advert?.district?.title != null && advert?.district?.title +' / '}
                {advert?.city?.title != null && advert?.city?.title +' / '}
                {advert?.country?.title != null && advert?.country?.title}
            </a>
          </div>
          <div className="property-meta d-flex align-items-center">
            <a className="text fz15" href="#">
              <i className="flaticon-bed pe-2 align-text-top" />
              {advert?.number_of_beds || '' + " "} {" "} {messages?.properties?.bed}
            </a>
            <a className="text ml20 fz15" href="#">
              <i className="flaticon-shower pe-2 align-text-top" />
               {advert?.number_of_bathrooms || '' + " "} {messages?.properties?.bath}
            </a>
            <a className="text ml20 fz15" href="#">
              <i className="flaticon-expand pe-2 align-text-top" />
              {advert?.square_meters || '' + " "} {messages?.properties?.sqft}
            </a>
          </div>
        </div>
      </div>

      <div className="col-lg-4">
        <div className="single-property-content">
          <div className="property-action text-lg-end">
            <div className="d-flex mb20 mb10-md align-items-center justify-content-lg-end">
              <a className="icon mr10" style={{cursor: 'pointer'}} onClick={() => compare(advert.id)}>
                <span className="flaticon-new-tab" />
              </a>
              <a className="icon mr10" style={{cursor: 'pointer'}} onClick={() => openModal(advert)}>
                <span className="flaticon-share-1" />
              </a>
              <a className="icon" style={{cursor: 'pointer'}} onClick={(e) => { e.preventDefault(); handlePrint(); }}>
                <span className="flaticon-printer" />
              </a>
            </div>
            <h3 className="price mb-0">{advert?.formatted_converted_price}</h3>
          </div>
        </div>
      </div>
      <div className="col-lg-4 mt15">
          <h3 className="advert-code">{advert?.code}</h3>
      </div>
      {currentListing && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Share Modal"
          ariaHideApp={false}
          style={{
            overlay: {
              zIndex: 1000,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              width: '40%',
              padding: '30px',
              position: 'relative',
            },
          }}
        >
          <button onClick={closeModal} style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'none',
            border: 'none',
            fontSize: '18px',
            cursor: 'pointer',
          }}>
            ✖
          </button>
          <div className="share-box">
            <div className="social-items">
              <div className="social-item">
                <a href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="Facebook">
                  <i className="fa-brands fa-facebook-f"></i>
                </a>
              </div>
              <div className="social-item">
                <a href={`https://twitter.com/intent/tweet?url=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="Twitter X">
                  <i className="fa-brands fa-twitter"></i>
                </a>
              </div>
              <div className="social-item">
                <a href={`https://www.linkedin.com/shareArticle?url=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="LinkedIn">
                  <i className="fa-brands fa-linkedin-in"></i>
                </a>
              </div>
              <div className="social-item">
                <a href={`https://pinterest.com/pin/create/button/?url=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="Pinterest">
                  <i className="fa-brands fa-pinterest-p"></i>
                </a>
              </div>
              <div className="social-item">
                <a href={`whatsapp://send?text=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="WhatsApp">
                  <i className="fa-brands fa-whatsapp"></i>
                </a>
              </div>
              <div className="social-item">
                <a href={`https://www.reddit.com/submit?url=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="Reddit">
                  <i className="fa-brands fa-reddit"></i>
                </a>
              </div>
              <div className="social-item">
                <a href={`https://t.me/share/url?url=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="Telegram">
                  <i className="fa-brands fa-telegram"></i>
                </a>
              </div>
              <div className="social-item">
                <a href={`https://www.tumblr.com/widgets/share/tool/preview?url=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="Tumblr">
                  <i className="fa-brands fa-tumblr"></i>
                </a>
              </div>
              <div className="social-item">
                <a href={`mailto:?body=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="Email">
                  <i className="fa-regular fa-envelope"></i>
                </a>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default PropertyHeader;
