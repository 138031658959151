import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import Modal from "react-modal";
import { useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import {useLocale} from "@/sites/context/locale.jsx";

const Hero = () => {
  const [ sliders, setSliders ] = useState(null)
  const { path1 } = useParams()
  const [ modalIsOpen, setModalIsOpen ] = useState(false)
  const [ currentListing, setCurrentListing ] = useState(null)
  const { messages, locale } = useLocale();

  useEffect(() => {
    const getSliders = async() => {
      try {
        const response = await axios.post('/api/sliders', {
            locale: locale
        })
        setSliders(response.data.sliders)
      }
      catch(error) {
        console.log(error)
      }
    }
    getSliders()
  },[])

  let lang = '';

  if (locale != 'en') {
    lang = '/' + path1;
  }

  const openModal = (listing) => {
    setCurrentListing(listing);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const compare = async(id) => {
    try {
      const response = await axios.post('/api/compare/add', { id });

      if (response.data.success && response.data.id) {
        const existingItems = Cookies.get('compare');
        const itemsArray = existingItems ? JSON.parse(existingItems) : [];

        if (!itemsArray.includes(response.data.id)) {
          if (itemsArray.length < 3) {
            itemsArray.push(response.data.id);
            Cookies.set('compare', JSON.stringify(itemsArray), { expires: 7 });
            window.location.reload()
          }
        }
      }
      else {

      }
    } catch (error) {
      console.error('Error in compare function:', error);
    }
  }

  return (
    <>
      <div className="hero-large-home9">
        <div className="rounded-arrow arrow-with-border d-flex flex-column d-position mt90">
          <button className="hero9-prev__active swiper_button _prev">
            <i className="far fa-chevron-left" />
          </button>

          <button className="hero9-next__active swiper_button _next">
            <i className="far fa-chevron-right" />
          </button>
        </div>

        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          speed={1400}
          autoplay={{ delay: 4000, disableOnInteraction: false }}
          modules={[Navigation]}
          navigation={{
            nextEl: ".hero9-next__active",
            prevEl: ".hero9-prev__active",
          }}
          className="hero_9"
          style={{ height: "70vh" }}
        >
          {sliders?.map((item, index) => (
            <SwiperSlide key={index}>
              <div
                className="item"
              >
                <div
                  className="slider-slide-item no-overlay"
                  style={{
                    backgroundImage: `url(${item.image})`,
                  }}
                  data-thumb={item.image}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-6 col-xl-5 mb20-md">
                        <h3 className="banner-title">{item.title}</h3>
                        <div className="slider-btn-block">
                          <a href={lang+'/properties/' + item.link}
                            className="ud-btn banner-btn fw500 btn-thm mt10 mt0-xs"
                          >
                              {messages?.home?.view_details}
                            <i className="fal fa-arrow-right-long" />
                          </a>
                        </div>
                      </div>

                      <div className="col-sm-6 col-xl-4 offset-xl-3">
                        <div className="listing-style1 at-home9-hero banner-content">
                          <div className="list-content">
                            <div className="list-price bgc-thm">
                              {item.formatted_converted_price}
                            </div>
                            <h6 className="list-title">
                              <a href={lang+'/properties/' + item.link}>
                                {item.title}
                              </a>
                            </h6>
                            <p className="list-text">{item.location}</p>
                            <div className="list-meta d-flex align-items-center">
                              <a href="#">
                                <span className="flaticon-bed" />
                                {item.number_of_beds} {messages?.properties?.bed}
                              </a>
                              <a href="#">
                                <span className="flaticon-shower" />
                                {item.number_of_bathrooms} {messages?.properties?.bath}
                              </a>
                              <a href="#">
                                <span className="flaticon-expand" />
                                {item.square_meters} {messages?.properties?.sqft}
                              </a>
                            </div>
                            <hr className="mt-2 mb-2" />
                            <div className="list-meta2 d-flex justify-content-between align-items-center">
                              <span className="for-what">{item.real_estate_status}</span>
                              <div className="icons d-flex align-items-center">
                                <a href={lang+'/properties/' + item.link}>
                                  <span className="flaticon-fullscreen" />
                                </a>
                                <a onClick={() => compare(item.id)}>
                                  <span className="flaticon-new-tab" />
                                </a>
                                <a onClick={() => openModal(item)}>
                                  <span className="flaticon-share-1" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {currentListing && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Share Modal"
          ariaHideApp={false}
          style={{
            overlay: {
              zIndex: 1000,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              width: '40%',
              padding: '30px',
              position: 'relative',
            },
          }}
        >
          <button onClick={closeModal} style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'none',
            border: 'none',
            fontSize: '18px',
            cursor: 'pointer',
          }}>
            ✖
          </button>
          <div className="share-box">
            <div className="social-items">
              <div className="social-item">
                <a href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="Facebook">
                  <i className="fa-brands fa-facebook-f"></i>
                </a>
              </div>
              <div className="social-item">
                <a href={`https://twitter.com/intent/tweet?url=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="Twitter X">
                  <i className="fa-brands fa-twitter"></i>
                </a>
              </div>
              <div className="social-item">
                <a href={`https://www.linkedin.com/shareArticle?url=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="LinkedIn">
                  <i className="fa-brands fa-linkedin-in"></i>
                </a>
              </div>
              <div className="social-item">
                <a href={`https://pinterest.com/pin/create/button/?url=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="Pinterest">
                  <i className="fa-brands fa-pinterest-p"></i>
                </a>
              </div>
              <div className="social-item">
                <a href={`whatsapp://send?text=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="WhatsApp">
                  <i className="fa-brands fa-whatsapp"></i>
                </a>
              </div>
              <div className="social-item">
                <a href={`https://www.reddit.com/submit?url=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="Reddit">
                  <i className="fa-brands fa-reddit"></i>
                </a>
              </div>
              <div className="social-item">
                <a href={`https://t.me/share/url?url=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="Telegram">
                  <i className="fa-brands fa-telegram"></i>
                </a>
              </div>
              <div className="social-item">
                <a href={`https://www.tumblr.com/widgets/share/tool/preview?url=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="Tumblr">
                  <i className="fa-brands fa-tumblr"></i>
                </a>
              </div>
              <div className="social-item">
                <a href={`mailto:?body=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="Email">
                  <i className="fa-regular fa-envelope"></i>
                </a>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Hero;
