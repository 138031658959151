import React, { useState, useEffect } from "react";
import {useLocale} from "../../context/locale";

const PropertyAddress = ({ advert }) => {
  const { messages } = useLocale();
  const [marker, setMarker] = useState(null);

  useEffect(() => {
    if (advert?.google_address) {
      const jsonString = advert.google_address;
      const jsonObject = JSON.parse(jsonString);

      const latLngObject = {
        lat: parseFloat(jsonObject?.x ? jsonObject?.x : jsonObject?.lat),
        lng: parseFloat(jsonObject?.y ? jsonObject?.y : jsonObject?.lng),
      };

      setMarker(latLngObject);
    }
  }, [advert?.google_address]);

  return (
    <>
      <div className="col-md-6">
        <div className="d-flex justify-content-between">
          <div className="pd-list">
            <p className="fw600 mb10 ff-heading dark-color">{messages?.properties?.country}</p>
            <p className="fw600 mb10 ff-heading dark-color">{messages?.properties?.city}</p>
            <p className="fw600 mb10 ff-heading dark-color">{messages?.properties?.district}</p>
            <p className="fw600 mb10 ff-heading dark-color">{messages?.properties?.region}</p>
          </div>
          <div className="pd-list">
            <p className="text mb10">{advert?.country?.title}</p>
            <p className="text mb10">{advert?.city?.title}</p>
            <p className="text mb10">{advert?.district?.title}</p>
            <p className="text mb10">{advert?.region?.title}</p>
          </div>
        </div>
      </div>

      <div className="col-md-12">
        {marker && (
          <iframe
            className="position-relative bdrs12 mt30 h250"
            loading="lazy"
            src={`https://maps.google.com/maps?q=${marker.lat},${marker.lng}&t=m&z=14&output=embed&iwloc=near`}
            title={`${marker.lat}, ${marker.lng}`}
            aria-label={`${marker.lat}, ${marker.lng}`}
          />
        )}
      </div>
    </>
  );
};

export default PropertyAddress;
