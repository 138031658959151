import Select from "react-select";

const Region = ({ region, setRegion, regionList }) => {

    const customStyles = {
        option: (styles, { isFocused, isSelected, isHovered }) => {
            return {
                ...styles,
                backgroundColor: isSelected
                    ? "rgb(164, 129, 17)"
                    : isHovered
                        ? "rgb(164, 129, 17)"
                        : isFocused
                            ? "rgb(164, 129, 17)"
                            : undefined,
            };
        },
    };

  return (
    <Select
      value={regionList.find((option) => option.value === region) || null}
      name="region"
      styles={customStyles}
      options={regionList}
      className="text-start select-borderless"
      classNamePrefix="select"
      onChange={(value) => setRegion(value.value)}
    />
  );
};

export default Region;
