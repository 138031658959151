import React, { useState } from "react";
import {useLocale} from "../../context/locale";

const Form = () => {
  const [ responseMessage, setResponseMessage ] = useState(null)
  const [ firstName, setFirstName ] = useState('')
  const [ lastName, setLastName ] = useState('')
  const [ email, setEmail ] = useState('')
  const [ message, setMessage ] = useState('')
  const { messages } = useLocale();

  const messageSend = async (e) => {
      e.preventDefault();
      try {
          const response = await axios.post('/api/message-send', {
              firstName: firstName,
              lastName: lastName,
              email: email,
              message: message
          });

          if (response.status === 200 || response.status === 201) {
              setResponseMessage(response.data.success);
          } else {
              if (response.data.errors) {
                  setResponseMessage(response.data.errors);
              } else if (response.data.error) {
                  setResponseMessage(response.data.error);
              }
          }
      } catch (error) {
          setResponseMessage('Bir hata oluştu.');
      }
  }

  return (
    <form className="form-style1" onSubmit={messageSend}>
      <div className="row">
        <div className="col-lg-12">
          <div className="mb20">
            <label className="heading-color ff-heading fw600 mb10">
                {messages?.contact?.first_name}
            </label>
            <input
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              type="text"
              className="form-control"
              placeholder={messages?.contact?.first_name}
              required
            />
          </div>
        </div>


        <div className="col-lg-12">
          <div className="mb20">
            <label className="heading-color ff-heading fw600 mb10">
                {messages?.contact?.last_name}
            </label>
            <input
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              type="text"
              className="form-control"
              placeholder={messages?.contact?.last_name}
              required
            />
          </div>
        </div>


        <div className="col-md-12">
          <div className="mb20">
            <label className="heading-color ff-heading fw600 mb10">{messages?.contact?.email}</label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              className="form-control"
              placeholder={messages?.contact?.email}
              required
            />
          </div>
        </div>

        <div className="col-md-12">
          <div className="mb10">
            <label className="heading-color ff-heading fw600 mb10">
                {messages?.contact?.message}
            </label>
            <textarea
              cols={30}
              rows={4}
              placeholder={messages?.contact?.message}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="col-md-12">
          <div className="d-grid">
            <button type="submit" className="ud-btn btn-thm">
                {messages?.contact?.submit}
              <i className="fal fa-arrow-right-long" />
            </button>
          </div>
        </div>

        <div className="col-md-12">
          <div className="d-grid text-center mt30">
          {responseMessage && <p>{responseMessage}</p>}
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;
