import DefaultHeader from "../../components/header/DefaultHeader";
import Footer from "../../components/footer";
import MobileMenu from "../../components/mobile-menu";
import FloorPlans from "../../components/advert/FloorPlans";
import InfoWithForm from "../../components/advert/more-info";
import NearbySimilarProperty from "../../components/advert/NearbySimilarProperty";
import OverView from "../../components/advert/OverView";
import PropertyAddress from "../../components/advert/PropertyAddress";
import PropertyFeaturesAminites from "../../components/advert/PropertyFeaturesAminites";
import PropertyHeader from "../../components/advert/PropertyHeader";
import PropertyVideo from "../../components/advert/PropertyVideo";
import ProperytyDescriptions from "../../components/advert/ProperytyDescriptions";
import ScheduleTour from "../../components/advert/ScheduleTour";
import PropertyGallery from "../../components/advert/PropertyGallery";
import MetaData from "../../components/MetaData";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {useLocale} from "../../context/locale";

const Advert = ({ settings }) => {
    const { messages, locale } = useLocale();
    const navigate = useNavigate()
    const { path1, path2, path3 } = useParams()
    const [ advert, setAdvert ] = useState(null)
    const [ adverts, setAdverts ] = useState([])
    useEffect(() => {
        const getAdvert = async() => {
            let cat1, cat2, cat3;

            if (locale != 'en') {
                cat1 = path2;
                cat2 = path3;
            } else {
                cat1 = path1;
                cat2 = path2;
            }

            try {
                const response = await axios.post('/api/advertdetail', {
                    locale: locale,
                    link: cat2,
                })
                if(response.data.error){
                    navigate('/')
                }
                if(response.data.advert) {
                    setAdvert(response.data.advert)
                    setAdverts(response.data.adverts)
                }
            }
            catch(error) {
                console.log(error)
            }
        }
        getAdvert()
    },[])

    const metaInformation = {
        title: settings?.company_name + ' | ' + advert?.title,
    };
    return (
        <>
            <MetaData meta={metaInformation} />
            <DefaultHeader settings={settings} />

            <MobileMenu settings={settings} />

            <section className="pt60 pb90 bgc-f7">
                <div className="container">
                    <div className="row">
                        <PropertyHeader advert={advert} />
                    </div>

                    <div className="row mb30 mt30">
                        <PropertyGallery advert={advert}/>
                    </div>

                    <div className="row wrap">
                        <div className="col-lg-8">
                            <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
                                <h4 className="title fz17 mb30">{messages?.properties?.overview}</h4>
                                <div className="row">
                                    <OverView advert={advert} />
                                </div>
                            </div>

                            <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
                                <h4 className="title fz17 mb30">{messages?.properties?.property_description}</h4>
                                <ProperytyDescriptions advert={advert} />
                            </div>

                            <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
                                <h4 className="title fz17 mb30 mt30">{messages?.properties?.address}</h4>
                                <div className="row">
                                    <PropertyAddress advert={advert} />
                                </div>
                            </div>
                            {advert?.real_estate_properties?.length > 0 &&
                                <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
                                    <h4 className="title fz17 mb30">{messages?.properties?.features_amenities}</h4>
                                    <div className="row">
                                        <PropertyFeaturesAminites advert={advert} />
                                    </div>
                                </div>
                            }

                            {advert?.floor_plans1 != null || advert?.floor_plans2 != null || advert?.floor_plans3 != null ?
                                <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
                                    <h4 className="title fz17 mb30">{messages?.properties?.floor_plans}</h4>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="accordion-style1 style2">
                                                <FloorPlans advert={advert} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            : ''}

                            {advert?.video != null &&
                                <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 ">
                                    <h4 className="title fz17 mb30">Video</h4>
                                    <div className="row">
                                        <PropertyVideo advert={advert} />
                                    </div>
                                </div>
                            }

                            <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
                                <h4 className="title fz17 mb30">{messages?.properties?.get_more_information}</h4>
                                <InfoWithForm settings={settings} />
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="column">
                                <div className="default-box-shadow1 bdrs12 bdr1 p30 mb30-md bgc-white position-relative">
                                    <h4 className="form-title mb5">{messages?.properties?.schedule_a_tour}</h4>
                                    <p className="text">{messages?.properties?.schedule_a_tour_desc}</p>
                                    <ScheduleTour />
                                </div>
                            </div>
                        </div>
                    </div>

                    {adverts?.length > 0 &&
                        <>
                            <div className="row mt30 align-items-center justify-content-between">
                                <div className="col-auto">
                                    <div className="main-title">
                                        <h2 className="title">{messages?.properties?.discover_our_featured_listings}</h2>
                                        <p className="paragraph">
                                            {messages?.properties?.discover_our_featured_listings_desc}
                                        </p>
                                    </div>
                                </div>

                                <div className="col-auto mb30">
                                    <div className="row align-items-center justify-content-center">
                                        <div className="col-auto">
                                            <button className="featured-prev__active swiper_button">
                                                <i className="far fa-arrow-left-long" />
                                            </button>
                                        </div>

                                        <div className="col-auto">
                                            <div className="pagination swiper--pagination featured-pagination__active" />
                                        </div>

                                        <div className="col-auto">
                                            <button className="featured-next__active swiper_button">
                                                <i className="far fa-arrow-right-long" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="property-city-slider">
                                        <NearbySimilarProperty adverts={adverts} />
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </section>

            <section className="footer-style1 at-home4 pt60 pb-0">
                <Footer settings={settings} />
            </section>
        </>
    );
};

export default Advert;
