import React from "react";
import {useLocale} from "../../context/locale";

const FloorPlans = ({ advert }) => {
  const { messages } = useLocale();
  const floorPlanData = [
    {
      id: "first-floor",
      title: messages?.properties?.first_floor,
      imageSrc: advert?.floor_plans1 || '',
    },
    {
      id: "second-floor",
      title: messages?.properties?.second_floor,
      imageSrc: advert?.floor_plans2 || '',
    },
    {
      id: "third-floor",
      title: messages?.properties?.third_floor,
      imageSrc: advert?.floor_plans3 || '',
    },
  ];


  return (
    <div className="accordion" id="accordionExample">
      {floorPlanData.map((floorPlan, index) => (
        <div
          className={`accordion-item ${index === 0 ? "active" : ""}`}
          key={floorPlan.id}
        >
          <h2 className="accordion-header" id={`heading${index}`}>
            <button
              className={`accordion-button ${index === 0 ? "" : "collapsed"}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse${index}`}
              aria-expanded={index === 0 ? "true" : "false"}
              aria-controls={`collapse${index}`}
            >
              <span className="w-100 d-md-flex align-items-center">
                <span className="mr10-sm">{floorPlan.title}</span>
              </span>
            </button>
          </h2>
          <div
            id={`collapse${index}`}
            className={`accordion-collapse collapse ${
              index === 0 ? "show" : ""
            }`}
            aria-labelledby={`heading${index}`}
            data-parent="#accordionExample"
          >
            <div className="accordion-body text-center">
              {floorPlan.imageSrc != '' &&
                <img

                  className="w-100 h-100 cover"
                  src={floorPlan.imageSrc}
                  alt="listing figureout"
                />
              }
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FloorPlans;
