import './bootstrap';
import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import {LocaleProvider} from "./sites/context/locale";

import { useEffect } from "react";
import { Mainpage, PageForward } from "./sites/pages";
import StartValution from './sites/pages/start-valution/page';
import ScrollToTop from "./sites/components/scroll/ScrollTop";
import ScrollTopBehaviour from "./sites/components/scroll/ScrollTopBehaviour";
import NotFound from "./sites/pages/not-found";
import CompareTable from './sites/pages/compare/index';
import Compare from './sites/components/Compare';
import Aos from "aos";
import "aos/dist/aos.css";
import "../../public/scss/main.scss";

if (typeof window !== "undefined") {
  import("bootstrap");
}



createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)


function App() {
  const [ settings, setSettings ] = useState()
    useEffect(() => {
      Aos.init({
        duration: 1200,
        once: true,
      });
    }, []);

    useEffect(() => {
      const getSettings = async() => {
        try {
          const response = await axios.get('/api/settings')
          setSettings(response.data.settings)
        }
        catch(error) {
          console.log(error)
        }
      }
      getSettings()
    }, [])

    return (
      <>
        <div className="wrapper ovh">
            <LocaleProvider>
              <BrowserRouter>
                <ScrollTopBehaviour />
                <Routes>
                  <Route path="/">
                    <Route index element={<Mainpage settings={settings} />} />
                    <Route path="/compare" element={<CompareTable settings={settings} />} />
                    <Route path="/start-valuation" element={<StartValution settings={settings} title="Start Valution" />} />
                    <Route path="/begin-sale-process" element={<StartValution settings={settings} title="Begin Sale Process" />} />
                    <Route path="/:path1/start-valuation" element={<StartValution settings={settings} title="Start Valution" />} />
                    <Route path="/:path1/begin-sale-process" element={<StartValution settings={settings} title="Begin Sale Process" />} />
                    <Route path="/:path1" element={<PageForward settings={settings} />} />
                    <Route path="/:path1/:path2" element={<PageForward settings={settings} />} />
                    <Route path="/:path1/:path2/:path3" element={<PageForward settings={settings} />} />
                    <Route path="/:path1/:path2/:path3/:path4" element={<PageForward settings={settings} />} />
                    <Route path="*" element={<NotFound settings={settings} />} />
                  </Route>
                </Routes>
                <Compare />
              </BrowserRouter>
              <ScrollToTop />
            </LocaleProvider>
        </div>
      </>
    );
  }

  export default App;
