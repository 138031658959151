import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import axios from "axios";
import {useLocale} from "../../context/locale";

const MenuWidget = () => {
    const { locale, messages } = useLocale();
    const [ footer, setFooter ] = useState(null);
    let lang = ''

    if(locale != 'en'){
        lang = '/' + locale
    }

    useEffect(() => {
        const getFooter = async() => {
            try {
                const response = await axios.post('/api/footer', {
                    locale: locale
                })
                setFooter(response.data.footer)
            }
            catch(error) {
                console.log(error)
            }
        }
        getFooter()
    }, []);

  return (
      <>
          {['popularsearchs', 'popularlocations', 'quicklinks'].map((menuType) => (
              <div className="col-auto" key={menuType}>
                  <div className="link-style1 light-style at-home9 mb-3">
                      {menuType === 'popularsearchs' && (
                          <>
                              <h6 className="mb25">{messages?.footer?.popular_search}</h6>
                              <div className="link-list">
                                  {footer?.filter((link) => link.menu === 'popularsearchs')
                                      .map((link, index) => (
                                          <a href={lang + "/" + link.content?.url} key={index}>
                                              {link.content?.title}
                                          </a>
                                      ))}
                              </div>
                          </>
                      )}
                      {menuType === 'popularlocations' && (
                          <>
                              <h6 className="mb25">{messages?.footer?.popular_locations}</h6>
                              <div className="link-list">
                                  {footer?.filter((link) => link.menu === 'popularlocations')
                                      .map((link, index) => (
                                          <a href={lang + "/" + link.content?.url} key={index}>
                                              {link.content?.title}
                                          </a>
                                      ))}
                              </div>
                          </>
                      )}
                      {menuType === 'quicklinks' && (
                          <>
                              <h6 className="mb25">{messages?.footer?.quick_links}</h6>
                              <div className="link-list">
                                  {footer?.filter((link) => link.menu === 'quicklinks')
                                      .map((link, index) => (
                                          <a href={lang + "/" + link.content?.url} key={index}>
                                              {link.content?.title}
                                          </a>
                                      ))}
                              </div>
                          </>
                      )}
                  </div>
              </div>
          ))}
      </>
  );
};

export default MenuWidget;
