import React, { useState } from "react";

const PropertyDescriptions = ({ advert }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const lines = advert?.content?.split('\n') || [];
  const firstTenLines = lines.slice(0, 6).join('\n');
  const remainingLines = lines.slice(6).join('\n');

  return (
    <>
      <div className="text mb10"> 
        <div dangerouslySetInnerHTML={{ __html: firstTenLines }} />
      </div>
      {remainingLines.length > 0 && (
        <div className="agent-single-accordion">
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item">
              <div
                id="flush-collapseOne"
                className={`accordion-collapse collapse ${isExpanded ? 'show' : ''}`}
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body p-0">
                  <div className="text">
                    <div dangerouslySetInnerHTML={{ __html: remainingLines }} />
                  </div>
                </div>
              </div>
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button p-0 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded={isExpanded}
                  aria-controls="flush-collapseOne"
                  onClick={() => setIsExpanded(!isExpanded)}
                >
                  {isExpanded ? 'Show less' : 'Show more'}
                </button>
              </h2>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PropertyDescriptions;
