import React, { useState } from "react";
import axios from "axios";
import {useLocale} from "../../context/locale";

const ScheduleTour = () => {
  const { messages } = useLocale();
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
    url: window.location.href
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('/api/advert-message', formData)
      if(response.data.error) {
        alert(response.data.error)
      }
      if(response.data.success) {
        alert(response.data.success)
      }
    } catch (error) {
      console.log(error)
    }
    setFormData({
      name: '',
      phone: '',
      email: '',
      message: '',
      url: window.location.href
    })
  }

  return (
    <div className="ps-navtab">
      <div className="tab-content" id="pills-tabContent">
        <form className="form-style1" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-12">
              <div className="mb20">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder={messages?.properties?.name}
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="col-lg-12">
              <div className="mb20">
                <input
                  type="text"
                  className="form-control"
                  name="phone"
                  placeholder={messages?.properties?.phone}
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="mb20">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder={messages?.properties?.email}
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="mb10">
                <textarea
                  cols={30}
                  rows={4}
                  name="message"
                  placeholder={messages?.properties?.message}
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="d-grid">
                <button type="submit" className="ud-btn btn-thm">
                    {messages?.properties?.submit_a_tour_request}
                  <i className="fal fa-arrow-right-long" />
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ScheduleTour;
