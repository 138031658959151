import Select from "react-select";
import { useState, useEffect } from "react";

const ListingStatus = ({ status, setStatus }) => {
  const options = [
    { value: "For Sale", label: "For Sale" },
    { value: "For Rent", label: "For Rent" },
    { value: "Assignment", label: "Assignment" },
    { value: "Sold", label: "Sold" },
    { value: "SSTC", label: "SSTC" },
    { value: "Under Offer", label: "Under Offer" },
    { value: "Reduced Price", label: "Reduced Price" },
  ];


    const customStyles = {
        option: (styles, { isFocused, isSelected, isHovered }) => {
            return {
                ...styles,
                backgroundColor: isSelected
                    ? "rgb(164, 129, 17)"
                    : isHovered
                        ? "rgb(164, 129, 17)"
                        : isFocused
                            ? "rgb(164, 129, 17)"
                            : undefined,
            };
        },
    };

  return (
    <Select
      name="status"
      value={options.find((option) => option.value === status) || null}
      styles={customStyles}
      options={options}
      className="select-custom filterSelect"
      classNamePrefix="select"
      onChange={(selectedOption) => setStatus(selectedOption.value)}
    />
  );
};

export default ListingStatus;
