import { isParentActive } from "../../utilis/isMenuActive";
import {useLocation } from "react-router-dom";
import React, {useEffect, useState} from "react";

import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import {useLocale} from "../../context/locale";


const ProSidebarContent = () => {
    const { pathname } = useLocation()
    const { locale } = useLocale()
    const [ category, setCategory ] = useState([]);

    useEffect(() => {
        const getCategory = async() => {
            try {
                const categoryResponse = await axios.post('/api/category', {
                    locale: locale
                });
                setCategory(categoryResponse.data.category)
            }
            catch(error) {
                console.log(error)
            }
        }
        getCategory()
    }, [])

  return (
    <Sidebar width="100%" backgroundColor="#fff" className="my-custom-class">
      <Menu>
        {category?.map((item, index) => (
          <SubMenu
            key={index}
            className={isParentActive(item.subcategory, pathname) ? "active" : ""}
            label={item.name}
          >
            {item.type == 1 && (
              <MenuItem
                component={
                  <a
                    className={item.url == pathname ? "active" : ""}
                    href={"/"+ item.url}
                  />
                }
              >
                {item.name}
              </MenuItem>
            )}

            {item.type == 2 && (
              <React.Fragment>
                  <MenuItem
                      component={
                          <a
                              className={item.url == pathname ? "active" : ""}
                              href={"/"+ item.url}
                          />
                      }
                  >
                      {item.name}
                  </MenuItem>
                {item.subcategory?.map((subcategory, subIndex) =>
                  <MenuItem
                    key={subIndex}
                    component={
                      <a
                        className={subcategory.url == pathname ? "active" : ""}
                        href={"/" + item.url + "/" + subcategory.url}
                      />
                    }
                  >
                    {subcategory.name}
                  </MenuItem>
                )}
              </React.Fragment>
            )}

            {item.type == 3 && (
              <React.Fragment>
                  <MenuItem
                      component={
                          <a
                              className={item.url == pathname ? "active" : ""}
                              href={"/"+ item.url}
                          />
                      }
                  >
                      {item.name}
                  </MenuItem>
                  {item.subcategory?.map((subcategory, subIndex) =>
                    subcategory.megacategory ? (
                      <SubMenu
                        key={subIndex}
                        label={subcategory.name}
                        className={
                          isParentActive(subcategory.megacategory, pathname) ? "active" : ""
                        }
                      >
                          <MenuItem
                              key={subIndex}
                              component={
                                  <a
                                      className={subcategory.url == pathname ? "active" : ""}
                                      href={"/" + item.url + "/" + subcategory.url}
                                  />
                              }
                          >
                              {subcategory.name}
                          </MenuItem>
                        {subcategory.megacategory?.map((megacategory, megaIndex) => (
                          <MenuItem
                            key={megaIndex}
                            component={
                              <a
                                className={megacategory.url == pathname ? "active" : ""}
                                href={"/"+ item.url + "/" + subcategory.url + "/" + megacategory.url}
                              />
                            }
                          >
                            {megacategory.name}
                          </MenuItem>
                        ))}
                      </SubMenu>
                    ) : (
                      <MenuItem
                        key={subIndex}
                        component={
                          <a
                            className={subcategory.url == pathname ? "active" : ""}
                            href={"/"+ item.url + "/" + subcategory.url}
                          />
                        }
                      >
                        {subcategory.name}
                      </MenuItem>
                    )
                  )}
              </React.Fragment>
            )}

          </SubMenu>
        ))}
      </Menu>
    </Sidebar>
  );
};

export default ProSidebarContent;
