import React, { useEffect } from "react";

const SearchBox = ({ propertyId, setPropertyId, name }) => {
  return (
    <div className="search_area">
      <input
        type="text"
        className="form-control"
        placeholder={name}
        value={propertyId != undefined ? propertyId : ''}
        onChange={(e) => setPropertyId(e.target.value)}
      />
      <label>
        <span className="flaticon-search" />
      </label>
    </div>
  );
};

export default SearchBox;
