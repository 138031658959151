import DefaultHeader from "../../components/header/DefaultHeader";
import Footer from "../../components/footer";
import MobileMenu from "../../components/mobile-menu";
import MetaData from "../../components/MetaData";
import React, { useState, useEffect } from "react";
import Cookies from 'js-cookie';

const CompareTable = ({ settings, category }) => {
  const [isValid, setIsValid] = useState([])

  const metaInformation = {
      title: settings?.company_name + ' | Compare' ,
  }

  useEffect(() => {
      const compareIds = Cookies.get('compare'); 
        
      if (compareIds) {
          const parsedIds = JSON.parse(compareIds);
          const getAdverts = async() => {
              try {
                  const response = await axios.post('/api/compare/list', {
                      adverts: parsedIds
                  })
                  if(response.data.adverts) {
                      setIsValid(response.data.adverts)
                  }
              }
              catch(error) {
                  console.log(error)
              }
          }
          getAdverts()

      } 
      else {
          setIsValid(false);
      }
  }, []);

  return (
    <>
    <MetaData meta={metaInformation} />
    <DefaultHeader settings={settings} category={category} />

    <MobileMenu settings={settings} category={category} />

      <section className="breadcumb-section3 p-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcumb-style1">
                <h2 className="title text-white">Compare Properties</h2>
                <div className="breadcumb-list">
                  <a className="text-white" href="#">
                    Home
                  </a>
                  <a className="text-white" href="#">
                    Compare
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our-compare">
        <div className="container">
          <div className="row wow fadeInUp" data-wow-delay="300ms">
            <div className="col-lg-12">
              <div className="table-style2 table-responsive">
              <table className="table table-borderless mb-0">
                <thead className="t-head">
                  <tr>
                    <th scope="col" />
                    {isValid?.map((value, index) => 
                      <th key={index} scope="col">{value.title}</th>
                    )}
                  </tr>
                </thead>

                <thead className="t-head2">
                  <tr>
                    <th scope="col" />
                    {isValid?.map((value, index) => 
                      <th scope="col" key={index}>
                        <div className="membership_header">
                          <div className="thumb">
                            <img
                              className="img-fluid mb-3"
                              src={value.image}
                              alt={value.description_seo}
                              style={{maxHeight: '200px'}}
                            />
                            <div className="h6 price mt-1">{value.formatted_converted_price}</div>
                            <p className="address mb-0">
                              {value?.country?.title}
                              {value?.city?.title != undefined ? ' ,'+value?.city?.title : ''}
                              {value?.district?.title != undefined ? ' ,'+value?.district?.title : ''}
                              {value?.region?.title != undefined ? ' ,'+value?.region?.title : ''}
                            </p>
                          </div>
                        </div>
                      </th>
                    )}
                  </tr>
                </thead>

                <tbody className="t-body">

                  <tr>
                    <th className="text-end" scope="row">
                      Property ID
                    </th>
                    {isValid?.map((value, index) =>
                      <td key={index}>{value?.code}</td>
                    )}
                  </tr>

                  <tr>
                    <th className="text-end" scope="row">
                      Property Type
                    </th>
                    {isValid?.map((value, index) =>
                      <td key={index}>
                        {value?.property_type?.map((value, index) => (
                          <React.Fragment key={index}>
                            {value.name}
                            <br />
                          </React.Fragment>
                        ))}
                      </td>
                    )}
                  </tr>

                  <tr>
                    <th className="text-end" scope="row">
                      Property Size
                    </th>
                    {isValid?.map((value, index) =>
                      <td key={index}>{value?.square_meters} Sq Ft</td>
                    )}
                  </tr>

                  <tr>
                    <th className="text-end" scope="row">
                      Bedrooms
                    </th>
                    {isValid?.map((value, index) =>
                      <td key={index}>{value?.number_of_beds}</td>
                    )}
                  </tr>

                  <tr>
                    <th className="text-end" scope="row">
                      Bathrooms{" "}
                    </th>
                    {isValid?.map((value, index) =>
                      <td key={index}>{value?.number_of_bathrooms}</td>
                    )}
                  </tr>

                  <tr>
                    <th className="text-end" scope="row">
                      Garage
                    </th>
                    {isValid?.map((value, index) =>
                      <td key={index}>{value?.carpark == 'Var' ?  <span className="fas fa-check" /> : <span className="fas fa-xmark" />}</td>
                    )}
                  </tr>

                  <tr>
                    <th className="text-end" scope="row">
                      Swimming Pool
                    </th>
                    {isValid?.map((value, index) =>
                      <td key={index}>{value?.swimming_pool == 'Var' ?  <span className="fas fa-check" /> : <span className="fas fa-xmark" />}</td>
                    )}
                  </tr>

                  <tr>
                    <th className="text-end" scope="row">
                      Year Built
                    </th>
                    {isValid?.map((value, index) =>
                      <td key={index}>{value?.building_age}</td>
                    )}
                  </tr>
                </tbody>
              </table>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="footer-style1 at-home4 pt60 pb-0">
        <Footer />
      </section>
    </>
  );
};

export default CompareTable;