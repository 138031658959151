import MainMenu from "./MainMenu";
import SidebarPanel from "./sidebar-panel";

import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import CurrencySelector from "./CurrencySelector";
import LangSelector from "./LangSelector";

const Header = ({ settings }) => {
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 10) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", changeBackground);
    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  }, []);

  return (
    <>
      <header
        className={`header-nav nav-homepage-style at-home2 main-menu sticky ${
          navbar ? "sticky slideInDown animated" : ""
        }`}
        style={
          {
          backgroundColor: navbar
            ? "rgba(255, 255, 255, 1)"
            : "rgba(255, 255, 255, 0.5)"
          }
        }
      >
        <nav className="posr">
          <div className="container maxw1800 posr">
            <div className="row align-items-center justify-content-between">
              <div className="col-auto">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="logos">
                    <Link className="header-logo logo1" to="/">
                      <img
                        src={settings?.logo}
                        alt={settings?.company_name}
                      />
                    </Link>
                    <Link className="header-logo logo2" to="/">
                      <img
                        src={settings?.logo}
                        alt={settings?.company_name}
                      />
                    </Link>
                  </div>

                  <MainMenu />
                </div>
              </div>

              <div className="col-auto">
                <div className="d-flex align-items-center gap-5">
                  <div className="d-flex align-items-center gap-5">
                    <LangSelector />
                    <CurrencySelector />
                  </div>
                  <a
                    className="sidemenu-btn filter-btn-right"
                    href="#"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#SidebarPanel"
                    aria-controls="SidebarPanelLabel"
                  >
                    <img

                      className="img-1"
                      src="/images/icon/nav-icon-white.svg"
                      alt={settings?.company_name}
                    />
                    <img

                      className="img-2"
                      src="/images/icon/nav-icon-dark.svg"
                      alt={settings?.company_name}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>

      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="SidebarPanel"
        aria-labelledby="SidebarPanelLabel"
      >
        <SidebarPanel settings={settings} />
      </div>
    </>
  );
};

export default Header;
