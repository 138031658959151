import PriceRange from "./PriceRange";
import SearchBox from "../../form/SearchBox";
import ListingStatus from "../../form/ListingStatus";
import PropertyType from "../../form/PropertyType";
import Bedroom from "../../form/Bedroom";
import Bathroom from "../../form/Bathroom";
import {useNavigate} from 'react-router-dom';
import {useLocale} from "../../../context/locale";

const AdvanceFilterModal = ({ propertyId, setPropertyId, status, setStatus, price, setPrice, bedrooms, setBedrooms, bathrooms, setBathrooms, propertyType, setPropertyType, handleSubmit, onReset }) => {
  const navigate = useNavigate();
  const { messages } = useLocale();

  return (
    <div className="modal-dialog modal-dialog-centered modal-lg">
      <div className="modal-content">
        <div className="modal-header pl30 pr30">
          <h5 className="modal-title" id="exampleModalLabel">
              {messages?.home?.more_filter}
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>

        <div className="modal-body pb-0">
          <div className="row">
            <div className="col-lg-12">
              <div className="widget-wrapper">
                <h6 className="list-title mb20">{messages?.home?.price_range}</h6>
                <div className="range-slider-style modal-version">
                  <PriceRange price={price} setPrice={setPrice} />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <div className="widget-wrapper">
                <h6 className="list-title">{messages?.home?.listing_status}</h6>
                <div className="form-style2 input-group">
                  <ListingStatus status={status} setStatus={setStatus} />
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="widget-wrapper">
                <h6 className="list-title">{messages?.home?.property_type}</h6>
                <div className="form-style2">
                  <PropertyType propertyType={propertyType} setPropertyType={setPropertyType} />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <div className="widget-wrapper">
                <h6 className="list-title">{messages?.home?.bedrooms}</h6>
                <div className="d-flex">
                  <Bedroom bedrooms={bedrooms} setBedrooms={setBedrooms} />
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="widget-wrapper">
                <h6 className="list-title">{messages?.home?.bathrooms}</h6>
                <div className="d-flex">
                  <Bathroom bathrooms={bathrooms} setBathrooms={setBathrooms} />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="widget-wrapper">
                <h6 className="list-title">{messages?.home?.property_id}</h6>
                <div className="form-style2">
                  <SearchBox propertyId={propertyId} setPropertyId={setPropertyId} name={messages?.home?.property_id} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-footer justify-content-between">
          <button className="reset-button" onClick={() => onReset()}>
            <span className="flaticon-turn-back" />
            <u>{messages?.home?.reset_button}</u>
          </button>
          <div className="btn-area">
            <button data-bs-dismiss="modal" type="submit" className="ud-btn btn-thm" onClick={() => handleSubmit()} >
              <span className="flaticon-search align-text-top pr10" />
                {messages?.home?.search_button}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvanceFilterModal;
