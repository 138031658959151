import Select from "react-select";

const City = ({ city, setCity, cityList }) => {

    const customStyles = {
        option: (styles, { isFocused, isSelected, isHovered }) => {
            return {
                ...styles,
                backgroundColor: isSelected
                    ? "rgb(164, 129, 17)"
                    : isHovered
                        ? "rgb(164, 129, 17)"
                        : isFocused
                            ? "rgb(164, 129, 17)"
                            : undefined,
            };
        },
    };

  return (
    <Select
      value={cityList.find((option) => option.value === city) || null}
      name="city"
      styles={customStyles}
      options={cityList}
      className="select-custom filterSelect"
      classNamePrefix="select"
      onChange={(value) => setCity(value.value)}
    />
  );
};

export default City;
