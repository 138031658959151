import ContactInfo from "./ContactInfo";
import Social from "./Social";
import ProSidebarContent from "./ProSidebarContent";
import {useLocale} from "../../context/locale";
import LangSelector from "@/sites/components/LangSelector.jsx";
import CurrencySelector from "@/sites/components/CurrencySelector.jsx";
import React from "react";

const MobileMenu = ({ settings }) => {
  const { messages } = useLocale();

  return (
    <div className="mobilie_header_nav stylehome1">
      <div className="mobile-menu">
        <div className="header innerpage-style">
          <div className="menu_and_widgets">
              <div className="mobile_menu_bar d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center gap-3" style={{height: "33px"}}>
                      <a
                          className="menubar"
                          href="#"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#mobileMenu"
                          aria-controls="mobileMenu"
                      >
                          <img
        
                              src="/images/mobile-dark-nav-icon.svg"
                              alt="mobile icon"
                          />
                      </a>
                      <div className="d-flex align-items-center gap-3">
                        <LangSelector />
                        <CurrencySelector />
                      </div>
                  </div>
                  <a className="mobile_logo" href="/">
                      <img

                          src={settings?.logo}
                          alt={settings?.company_name}
                      />
                  </a>
              </div>
          </div>
        </div>
      </div>
        <div
            className="offcanvas offcanvas-start mobile_menu-canvas"
            tabIndex="-1"
            id="mobileMenu"
            aria-labelledby="mobileMenuLabel"
            data-bs-scroll="true"
        >
            <div className="rightside-hidden-bar">
                <div className="hsidebar-header">
                    <div
                        className="sidebar-close-icon"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <span className="far fa-times"></span>
            </div>
            <h4 className="title">{settings?.company_name}</h4>
          </div>

          <div className="hsidebar-content ">
            <div className="hiddenbar_navbar_content">
              <ProSidebarContent />

              <div className="hiddenbar_footer position-relative bdrt1">
                <div className="row pt45 pb30 pl30">
              <ContactInfo settings={settings} />
                </div>

                <div className="row pt30 pb30 bdrt1">
                  <div className="col-auto">
                    <div className="social-style-sidebar d-flex align-items-center pl30">
                      <h6 className="me-4 mb-0">{messages?.home?.view_details}</h6>
                      <Social settings={settings} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
