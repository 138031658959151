import React, { useEffect, useState } from 'react';
import Select from "react-select";
import Cookies from 'js-cookie';
import {useLocale} from "../context/locale"
import CountryFlag from 'react-country-flag'

const LangSelector = () => {
    const { locale, changeLang } = useLocale();
    const [ langList, setLangList ] = useState([])
    useEffect(() => {
        const getLocales = async() => {
            try {
                const response = await axios.get('/api/locales')
                const updatedLangList = response.data.locales.map(value => ({ label: value.name, value: value.locale }));
                setLangList(updatedLangList);
            }
            catch(error) {
                console.log(error)
            }
        }
        getLocales()
    },[])

    const langChange = (value) => {
        changeLang(value)
    }

    const customStyles = {
        option: (styles, { isFocused, isSelected, isHovered }) => {
            return {
                ...styles,
                backgroundColor: isSelected
                    ? "rgb(164, 129, 17)"
                    : isHovered
                        ? "rgb(164, 129, 17)"
                        : isFocused
                            ? "rgb(164, 129, 17)"
                            : undefined,
            };
        },
    };

    const formatOptionLabel = ({ label, value }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <CountryFlag
                countryCode={
                value == 'en' || value == 'ar' ?
                    value == 'en' ? 'gb' : 'sa'
                :
                    value
                }
                svg
                style={{ width: 20, height: 15, marginRight: 8 }}
            />
            {value.toUpperCase()}
        </div>
    );

    return (
        <Select
            name="locales"
            value={langList.find(option => option.value === locale) || null}
            onChange={(selectedOption) => langChange(selectedOption.value)}
            styles={customStyles}
            options={langList}
            className="text-start select-borderless"
            classNamePrefix="select"
            formatOptionLabel={formatOptionLabel}
        />
    );
};

export default LangSelector;


