import Select from "react-select";

const District = ({ district, setDistrict, districtList }) => {

    const customStyles = {
        option: (styles, { isFocused, isSelected, isHovered }) => {
            return {
                ...styles,
                backgroundColor: isSelected
                    ? "rgb(164, 129, 17)"
                    : isHovered
                        ? "rgb(164, 129, 17)"
                        : isFocused
                            ? "rgb(164, 129, 17)"
                            : undefined,
            };
        },
    };

  return (
    <Select
      value={districtList.find((option) => option.value === district) || null}
      name="district"
      styles={customStyles}
      options={districtList}
      className="text-start select-borderless"
      classNamePrefix="select"
      onChange={(value) => setDistrict(value.value)}
    />
  );
};

export default District;
