import React from "react";

const SingleAgentInfo = ({ settings }) => {
  const socialMedia = [
     {
        name: "facebook",
        link: settings?.facebook
     },
     {
        name: "twitter",
        link: settings?.twitter
     },
     {
        name: "instagram",
        link: settings?.instagram
     },
     {
        name: "linkedin",
        link: settings?.linkedin
     }
  ]

  return (
    <div className="agent-single d-sm-flex align-items-center bdrb1 mb30 pb25">
      <div className="single-img mb30-sm">
        <img
         
          className="w90"
          src={settings?.logo}
          alt={settings?.company_name}
        />
      </div>
      <div className="single-contant ml30 ml0-xs">
        <h6 className="title mb-1">{settings?.company_name}</h6>
        <div className="agent-meta mb10 d-md-flex align-items-center">
          <a className="text fz15 pe-2 bdrr1" href={`tel:${settings?.phone}`}>
            <i className="flaticon-call pe-1 ps-1" />
            {settings?.phone}
          </a>
          <a className="text fz15 ps-2" href={`https://wa.me/${settings?.whatsapp}`}>
            <i className="flaticon-whatsapp pe-1" />
            WhatsApp
          </a>
        </div>
        <div className="agent-social">
          {socialMedia?.map((social, index) => (
            <a key={index} className="mr20" href={social.link}>
              <i className={`fab fa-${social.name}`} />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SingleAgentInfo;
