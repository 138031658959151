import DefaultHeader from "../../components/header/DefaultHeader";
import Footer from "../../components/footer";
import MobileMenu from "../../components/mobile-menu";
import Form from "../../components/contact/Form";
import MetaData from "../../components/MetaData";
import { useEffect, useState } from "react";
import {useLocale} from "../../context/locale";

const Contact = ({ settings, content }) => {
  const metaInformation = {
      title: settings?.company_name + ' | ' + content?.title,
  }
 const { messages } = useLocale();

  const [ branches, setBranches ] = useState([])

  useEffect(() => {
    const getBranches = async() => {

      try {
          const response = await axios.get('/api/branches')
          setBranches(response.data.branche)
      }
      catch(error) {
          console.log(error)
      }
    }
    getBranches()
  },[])

  return (
    <>
    <MetaData meta={metaInformation} />
      <DefaultHeader settings={settings} />

      <MobileMenu settings={settings} />

      <section className="p-0">
        <iframe
          className="home8-map contact-page"
          loading="lazy"
          src={settings?.google_map}
          title={settings?.title}
          aria-label={settings?.title}
        />
      </section>

      <section>
        <div className="container">
          <div className="row d-flex align-items-end">
            <div className="col-lg-5 position-relative">
              <div className="home8-contact-form default-box-shadow1 bdrs12 bdr1 p30 mb30-md bgc-white">
                <h4 className="form-title mb25">
                  Have questions? Get in touch!
                </h4>
                <Form />
              </div>
            </div>

            <div className="col-lg-5 offset-lg-2">
              <h2 className="mb30 text-capitalize">
                {settings?.contact_title}
              </h2>
              <p className="text">
                {settings?.contact_content}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="pt0 pb90 pb10-md">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 m-auto"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="main-title text-center">
                <h2 className="title">{messages?.contact?.visit_our_office}</h2>
                <p className="paragraph">
                    {messages?.contact?.visit_our_office_desc}
                </p>
              </div>
            </div>
          </div>

          <div className="row" data-aos="fade-up" data-aos-delay="100">
            {branches?.map((value, index) =>
            <div className="col-sm-6 col-lg-4" key={index}>
              <div className="iconbox-style8 text-center">
                <div className="icon">
                  <img src="/images/icon/paris.svg" alt="icon" />
                </div>
                <div className="iconbox-content">
                  <h4 className="title">{value?.name}</h4>
                  <p className="text mb-1">{value?.address}</p>
                  <h6 className="mb10">{value?.phone}</h6>
                  <a className="text-decoration-underline" href={value?.google_map}>
                    Open Google Map
                  </a>
                </div>
              </div>
            </div>
            )}
          </div>
        </div>
      </section>

      <section className="footer-style1 at-home4 pt60 pb-0">
        <Footer settings={settings} />
      </section>
    </>
  );
};

export default Contact;
