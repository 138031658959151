

import React from "react";
import SquareFeet from "./SquareFeet";
import YearBuilt from "./YearBuilt";

const OtherFeatures = ({filterFunctions}) => {
  const featuresLeftColumn = [
    { label: "Attic" },
    { label: "Basketball court", defaultChecked: true },
    { label: "Air Conditioning", defaultChecked: true },
    { label: "Lawn", defaultChecked: true },
    { label: "TV Cable" },
    { label: "Dryer" },
  ];

  const featuresRightColumn = [
    { label: "Outdoor Shower" },
    { label: "Washer" },
    { label: "Lake view" },
    { label: "Wine cellar" },
    { label: "Front yard" },
    { label: "Refrigerator" },
  ];



  return (
    <div className="row">
      <div className="col-lg-6">
        <div className="checkbox-style1">
          {featuresLeftColumn.map((feature, index) => (
            <label className="custom_checkbox" key={index}>
              {feature.label}
              <input
             type="checkbox" />
              <span className="checkmark" />
            </label>
          ))}
        </div>
      </div>

      <div className="col-lg-6 mb30">
        <div className="checkbox-style1">
          {featuresRightColumn.map((feature, index) => (
            <label className="custom_checkbox" key={index}>
              {feature.label}

              <input type="checkbox" />
              <span className="checkmark" />
            </label>
          ))}
        </div>
      </div>
      <div className="col-lg-12 mb30">
        <h6 className="list-title">Square Feet</h6>
        <SquareFeet />
      </div>

      <div className="col-lg-12">
        <h6 className="list-title">Year Built</h6>
        <YearBuilt />
      </div>
    </div>
  );
};

export default OtherFeatures;
