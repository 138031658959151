import React from "react";
import SearchBox from "./SearchBox";
import ListingStatus from "./ListingStatus";
import PropertyType from "./PropertyType";
import PriceSlider from "./PriceRange";
import Bedroom from "./Bedroom";
import Bathroom from "./Bathroom";
import Country from "./Country";
import City from "./City";
import District from "./District";
import Region from "./Region";
import OtherFeatures from "./OtherFeatures";
import {useLocale} from "../../context/locale";

const ListingSidebar = ({
  searchData,
  onreset,
  country,
  city,
  district,
  region,
  propertyId,
  status,
  propertyType,
  price,
  bedrooms,
  bathrooms,
  setCountry,
  setCity,
  setDistrict,
  setRegion,
  setPropertyId,
  setStatus,
  setPropertyType,
  setPrice,
  setBedrooms,
  setBathrooms,
  countryFetch,
  cityFetch,
  districtFetch,
  cityList,
  setCityList,
  districtList,
  setDistrictList,
  regionList,
  setRegionList
}) => {

  const { messages } = useLocale();

  return (
    <div className="list-sidebar-style1">

      <div className="widget-wrapper advance-feature-modal">
        <h6 className="list-title">{messages?.properties_filter?.country}</h6>
        <div className="form-style2 input-group">
          <Country country={country} setCountry={countryFetch} />
        </div>
      </div>

      <div className="widget-wrapper advance-feature-modal">
        <h6 className="list-title">{messages?.properties_filter?.city}</h6>
        <div className="form-style2 input-group">
          <City city={city} setCity={cityFetch} cityList={cityList}  />
        </div>
      </div>

      <div className="widget-wrapper advance-feature-modal">
        <h6 className="list-title">{messages?.properties_filter?.district}</h6>
        <div className="form-style2 input-group">
          <District district={district} setDistrict={districtFetch} districtList={districtList} />
        </div>
      </div>

      <div className="widget-wrapper advance-feature-modal">
        <h6 className="list-title">{messages?.properties_filter?.region}</h6>
        <div className="form-style2 input-group">
          <Region region={region} setRegion={setRegion} regionList={regionList} />
        </div>
      </div>

      <div className="widget-wrapper">
        <h6 className="list-title">{messages?.properties_filter?.property_id}</h6>
        <SearchBox propertyId={propertyId} setPropertyId={setPropertyId} name={messages?.properties_filter?.property_id} />
      </div>

      <div className="widget-wrapper">
        <h6 className="list-title">{messages?.properties_filter?.listing_status}</h6>
        <div className="radio-element">
          <ListingStatus status={status} setStatus={setStatus} />
        </div>
      </div>

      <div className="widget-wrapper">
        <h6 className="list-title">{messages?.properties_filter?.property_type}</h6>
        <div className="checkbox-style1">
          <PropertyType propertyType={propertyType} setPropertyType={setPropertyType} />
        </div>
      </div>

      <div className="widget-wrapper">
        <h6 className="list-title">{messages?.properties_filter?.price_range}</h6>
        <div className="range-slider-style1">
          <PriceSlider price={price} setPrice={setPrice} />
        </div>
      </div>

      <div className="widget-wrapper">
        <h6 className="list-title">{messages?.properties_filter?.bedrooms}</h6>
        <div className="d-flex">
          <Bedroom bedrooms={bedrooms} setBedrooms={setBedrooms} />
        </div>
      </div>

      <div className="widget-wrapper">
        <h6 className="list-title">{messages?.properties_filter?.bathrooms}</h6>
        <div className="d-flex">
          <Bathroom bathrooms={bathrooms} setBathrooms={setBathrooms} />
        </div>
      </div>

      <div className="widget-wrapper">
        <div className="feature-accordion">
          <div className="accordion" id="accordionExample">
            <div className="accordion-item border-none">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button border-none p-0 after-none feature-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <span className="flaticon-settings" /> {messages?.properties_filter?.other_features}
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body p-0 mt15">
                  <OtherFeatures />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="widget-wrapper mb20">
        <div className="btn-area d-grid align-items-center">
          <button className="ud-btn btn-thm" onClick={() => searchData()}>
            <span className="flaticon-search align-text-top pr10" />
              {messages?.properties_filter?.search_button}
          </button>
        </div>
      </div>

      <div className="reset-area d-flex align-items-center justify-content-between">
        <div className="reset-button cursor" href="#">
          <span className="flaticon-turn-back" />
          <u onClick={() => onreset()}>{messages?.properties_filter?.reset_button}</u>
        </div>
      </div>
    </div>
  );
};

export default ListingSidebar;
