import React, { useEffect, useState } from 'react';
import Select from "react-select";
import Cookies from 'js-cookie';

const CurrencySelector = () => {
    const [currency, setCurrency] = useState('USD');

    useEffect(() => {
        const savedCurrency = Cookies.get('currency');
        if (savedCurrency) {
            setCurrency(savedCurrency);
        }
    }, []);

    const handleCurrencyChange = (value) => {
        Cookies.set('currency', value, { expires: 30 });
        setCurrency(value);
        window.location.reload()
    };

    const currencyList = [
        {
            label: 'USD',
            value: 'USD'
        },
        {
            label: 'EUR',
            value: 'EUR'
        },
        {
            label: 'GBP',
            value: 'GBP'
        },
        {
            label: 'TRY',
            value: 'TRY'
        }
    ]

    const customStyles = {
        option: (styles, { isFocused, isSelected, isHovered }) => {
            return {
                ...styles,
                backgroundColor: isSelected
                    ? "rgb(164, 129, 17)"
                    : isHovered
                        ? "rgb(164, 129, 17)"
                        : isFocused
                            ? "rgb(164, 129, 17)"
                            : undefined,
            };
        },
    };

    return (
        <Select
            name="currency"
            value={currencyList?.find((option) => option.value === currency) || null}
            styles={customStyles}
            options={currencyList}
            className="text-start select-borderless"
            classNamePrefix="select"
            onChange={(value) => handleCurrencyChange(value?.value)}
        />
    );
};

export default CurrencySelector;


