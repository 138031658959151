import DefaultHeader from "../../components/header/DefaultHeader";
import Footer from "../../components/footer";
import MobileMenu from "../../components/mobile-menu";
import MetaData from "../../components/MetaData";
import PropertyType from "../../components/form/PropertyType";
import PriceSlider from "../../components/form/PriceRange";
import Bedroom from "../../components/form/Bedroom";
import Bathroom from "../../components/form/Bathroom";
import { useState } from "react";
import {useLocale} from "@/sites/context/locale.jsx";

const StartValution = ({ settings, category, title }) => {
    const { messages } = useLocale();

    const metaInformation = {
        title: settings?.company_name + " | " + title,
    };

    const [ responseMessage, setResponseMessage ] = useState(null)

    const [ fullName, setFullName ] = useState('')
    const [ preferredDate, setPreferredDate ] = useState('')
    const [ additionalDetails, setAdditionalDetails ] = useState('')
    const [ phone, setPhone ] = useState('')
    const [ email, setEmail ] = useState('')
    const [ propertyType, setPropertyType ] = useState('')
    const [ price, setPrice ] = useState({ min: 20, max: 10000000 })
    const [ bedrooms, setBedrooms ] = useState(0)
    const [ bathrooms, setBathrooms ] = useState(0)

    const url = title == 'Start Valution' ? 'start-valution' : 'begin-sale-proccess';

    const sendMessage = async(e) => {
      e.preventDefault()

        try {
          const response = await axios.post('/api/' + url, {
              fullName: fullName,
              preferredDate: preferredDate,
              additionalDetails: additionalDetails,
              phone: phone,
              email: email,
              propertyType: JSON.stringify(propertyType),
              price: JSON.stringify(price),
              bedrooms: bedrooms.toString(),
              bathrooms: bathrooms.toString()
          });

          if (response.status === 200 || response.status === 201) {
              setResponseMessage(response.data.success);
          } else {
              setResponseMessage(response.data.error);
          }
        } catch (error) {
            setResponseMessage(error.response.data.error);
        }

    }

  return (
    <>
    <MetaData meta={metaInformation} />
      <DefaultHeader settings={settings} category={category} />

      <MobileMenu settings={settings} category={category} />

      <section className="pt30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcumb-style1">
                <h2 className="title">
                    {title == 'Start Valution' ?
                        messages?.home?.start_valution
                        :
                        messages?.home?.begin_sale_process
                    }
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container mb90">
        <form className="row col-md-8 offset-1" onSubmit={sendMessage}>
          <div className="col-lg-12">
            <div className="mb20">
              <label className="heading-color ff-heading fw600 mb10">
                  {messages.contact?.full_name}
              </label>
              <input
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                type="text"
                className="form-control"
                placeholder={messages.contact?.full_name}
                required
              />
            </div>
          </div>
          <div>
            <div className="mb20">
              <label className="heading-color ff-heading fw600 mb10">
                  {messages.contact?.phone}
              </label>
              <input
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                type="number"
                className="form-control"
                placeholder={messages.contact?.phone}
                required
              />
            </div>
          </div>
          <div>
            <div className="mb20">
              <label className="heading-color ff-heading fw600 mb10">
                  {messages.contact?.email}
              </label>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                className="form-control"
                placeholder={messages.contact?.email}
                required
              />
            </div>
          </div>
          <div className="widget-wrapper">
            <h6 className="list-title">{messages.contact?.estimated_price}</h6>
            <div className="range-slider-style1">
              <PriceSlider price={price} setPrice={setPrice} />
            </div>
          </div>
          <div className="widget-wrapper">
            <h6 className="list-title">{messages.contact?.property_type}</h6>
            <div className="checkbox-style1">
              <PropertyType propertyType={propertyType} setPropertyType={setPropertyType} />
            </div>
          </div>
          <div className="widget-wrapper col-md-6">
            <h6 className="list-title">{messages.contact?.bedrooms}</h6>
            <div className="d-flex">
              <Bedroom bedrooms={bedrooms} setBedrooms={setBedrooms} />
            </div>
          </div>
          <div className="widget-wrapper col-md-6">
            <h6 className="list-title">{messages.contact?.bathrooms}</h6>
            <div className="d-flex">
              <Bathroom bathrooms={bathrooms} setBathrooms={setBathrooms} />
            </div>
          </div>
          <div>
            <div className="mb20">
              <label className="heading-color ff-heading fw600 mb10">
                 {messages.contact?.preferred_date}
              </label>
              <input
                value={preferredDate}
                onChange={(e) => setPreferredDate(e.target.value)}
                type="date"
                className="form-control"
                placeholder={messages.contact?.preferred_date}
                required
              />
            </div>
          </div>
          <div>
            <div className="mb10">
              <label className="heading-color ff-heading fw600 mb10">
                  {messages.contact?.additional_details}
              </label>
              <textarea
                onChange={(e) => setAdditionalDetails(e.target.value)}
                cols={30}
                rows={4}
                placeholder={messages.contact?.additional_details}
                defaultValue={""}
                required
              />
            </div>
          </div>
          <div>
            <div className="d-grid">
              <button type="submit" className="ud-btn btn-thm">
                  {messages.contact?.submit}
                <i className="fal fa-arrow-right-long" />
              </button>
            </div>
          </div>
        </form>
        {responseMessage != "" && <h4 className="mb30 mt30 text-center">{responseMessage}</h4>}
      </div>

      <section className="footer-style1 at-home4 pt60 pb-0">
        <Footer settings={settings} />
      </section>
    </>
  );
};

export default StartValution;
