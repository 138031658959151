import Select from "react-select";
import { useState, useEffect } from "react";

const Country = ({ country, setCountry }) => {

  const [ countryList, setCountryList ] = useState()

  useEffect(() => {
      const countryFetch = async () => {
        try {
          const response = await axios.get('/api/country');
          const data = response.data.country;

          setCountryList([
            ...data.map((value) => ({ label: value.title, value: value.id }))
          ]);
        } catch (error) {
          console.log(error);
        }
      }
      countryFetch()
  },[])

    const customStyles = {
        option: (styles, { isFocused, isSelected, isHovered }) => {
            return {
                ...styles,
                backgroundColor: isSelected
                    ? "rgb(164, 129, 17)"
                    : isHovered
                        ? "rgb(164, 129, 17)"
                        : isFocused
                            ? "rgb(164, 129, 17)"
                            : undefined,
            };
        },
    };

  return (
    <Select
      name="country"
      value={countryList?.find((option) => option.value === country) || null}
      styles={customStyles}
      options={countryList}
      className="text-start select-borderless"
      classNamePrefix="select"
      onChange={(value) => setCountry(value.value)}
    />
  );
};

export default Country;

