import BlogFilterContainer from "../../components/blog-filter/BlogFilterContainer";
import DefaultHeader from "../../components/header/DefaultHeader";
import Footer from "../../components/footer";
import MobileMenu from "../../components/mobile-menu";
import MetaData from "../../components/MetaData";

const Blog = ({ settings, content }) => {
  const metaInformation = {
      title: settings?.company_name + ' | ' + content?.title
  };

  return (
    <>
    <MetaData meta={metaInformation} />
      <DefaultHeader settings={settings} />

      <MobileMenu settings={settings} />

      <section className="breadcumb-section2 p-0" style={{ backgroundImage: `url(${content?.image})` }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcumb-style1">
                <h2 className="title">Blog</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <BlogFilterContainer content={content} />

      <section className="footer-style1 at-home4 pt60 pb-0">
        <Footer settings={settings} />
      </section>
    </>
  );
};

export default Blog;
