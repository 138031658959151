import MetaData from "../components/MetaData";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";

import Wrapper from "../components/layout-wrapper/wrapper";
import Home from "./home";
import About from "./about";
import Blog from "./blogs";
import Contact from "./contact";
import DefaultPage from './default'
import Faq from "./faq";
import Advert from './advert';
import BlogSingle from "./blogs/blogs";
import { useLocale } from "../context/locale";


export function Mainpage({ settings }) {
  const metaInformation = {
    title: settings?.company_name + " | Home",
  };

  const { locale } = useLocale();

  return (
    <Wrapper>
      <MetaData meta={metaInformation} />
      <Home settings={settings} />
    </Wrapper>
  )
}

export function PageForward({ settings }) {
  const { locale } = useLocale();

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { path1, path2, path3, path4 } = useParams()
  const [ page, setPage ] = useState('pending')
  const [ advert, setAdvert ] = useState(null)
  const [ blog, setBlog ] = useState(null)


  useEffect(() => {
    const getCategory = async() => {
      let cat1, cat2, cat3;
      if (locale != 'en') {
        cat1 = path2;
        cat2 = path3;
        cat3 = path4;
      } else {
        cat1 = path1;
        cat2 = path2;
        cat3 = path3;
      }

      try {
        const response = await axios.post('/api/page', {
          locale: locale,
          category: cat1,
          subcategory: cat2,
          megacategory: cat3
        })
        if(response.data.error){
          locale != 'en' ? '' : navigate('/')
        }
        if(response.data.category) {
          setPage(response.data.category)
        }

        if(response.data.content) {
          setPage({ content: response.data.content })
        }

        if(response.data.advert) {
          setPage('')
          setAdvert(response.data.advert)
        }

        if(response.data.blog) {
          setPage('')
          setBlog(response.data.blog)
        }
      }
      catch(error) {
        console.log(error)
      }
    }
    getCategory()
  },[pathname])

  return (
    <React.Fragment>
      {( (locale != 'en') && (path2 === undefined && path3 === undefined && path4 === undefined) ) ?
       <Home settings={settings} content={page.content} />
      :
        <>
          {advert != null && page == '' ?
            <Advert settings={settings} />
          :
          <>
              {blog != null && page == '' ?
                <BlogSingle settings={settings} blog={blog} />
              :
              <>
                {page?.content == null || page?.content == '' ?
                <>
                  <DefaultPage path1={path1} settings={settings} content={page.content} />
                </>
                :
                  <>
                    {page.content?.template == 'home' &&
                      <Home settings={settings} content={page.content} />
                    }
                    {page.content?.template == 'about' &&
                      <About settings={settings} content={page.content} />
                    }
                    {page.content?.template == 'contact' &&
                      <Contact settings={settings} content={page.content} />
                    }
                    {page.content?.template == 'blog' &&
                      <Blog settings={settings} content={page.content} />
                    }
                    {page.content?.template == 'default' &&
                      <DefaultPage settings={settings} content={page.content} />
                    }
                    {page.content?.template == 'faqs' &&
                      <Faq settings={settings} content={page.content} />
                    }
                    {page.content?.template == null &&
                      <DefaultPage path1={path1} settings={settings} content={page.content} />
                    }
                  </>
                }
              </>
            }
          </>
        }
      </>
    }
    </React.Fragment>
  )
}
