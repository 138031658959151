import { useState } from "react";
import AdvanceFilterModal from "./advance-filter";
import FilterContent from "./FilterContent";

const Hero = () => {

  const [ cityList, setCityList ] = useState([])
  const [ districtList, setDistrictList ] = useState([])
  const [ regionList, setRegionList ] = useState([])

  const [ country, setCountry ] = useState('')
  const [ city, setCity ] = useState('')
  const [ district, setDistrict ] = useState('')
  const [ region, setRegion ] = useState('')
  const [ propertyId, setPropertyId ] = useState('')
  const [ status, setStatus ] = useState('')
  const [ propertyType, setPropertyType ] = useState('')
  const [ price, setPrice ] = useState({ min: 20, max: 10000000 })
  const [ bedrooms, setBedrooms ] = useState(0)
  const [ bathrooms, setBathrooms ] = useState(0)

  const countryFetch = async (id) => {
    setCity(null)
    setCityList([])
    setDistrict(null)
    setDistrictList([])
    setRegion(null)
    setRegionList([])
    setCountry(id)
    const response = await axios.post('/api/city', { country_id: id });
    const data = response.data.city;

    setCityList([
      ...data.map((value) => ({ label: value.title, value: value.id }))
    ])
  }

  const cityFetch = async (id) => {
    setDistrict(null)
    setDistrictList([])
    setRegion(null)
    setRegionList([])
    setCity(id)
    const response = await axios.post('/api/district', { city_id: id });
    const data = response.data.district;

    setDistrictList([
      ...data.map((value) => ({ label: value.title, value: value.id }))
    ])
  }

  const districtFetch = async (id) => {
    setRegion(null)
    setRegionList([])
    setDistrict(id)
    const response = await axios.post('/api/neighborhood', { district_id: id });
    const data = response.data.neighborhood;

    setRegionList([
      ...data.map((value) => ({ label: value.title, value: value.id }))
    ])
  }

  const handleSubmit = () => {
    const queryParams = new URLSearchParams({
      country: country || '',
      city: city || '',
      district: district || '',
      region: region || '',
      propertyId: propertyId || '',
      status: status || '',
      propertyType: propertyType || '',
      min: price.min,
      max: price.max,
      bedrooms: bedrooms || 0,
      bathrooms: bathrooms || 0
    }).toString();

    window.location.href = `/properties?${queryParams}`;
  };

  const onReset = () => {
    setCountry('')
    setCity('')
    setDistrict('')
    setRegion('')
    setPropertyId('')
    setStatus('')
    setPropertyType('')
    setPrice({ min: 20, max: 10000000 })
    setBedrooms('')
    setBedrooms(0)
    setBathrooms(0)
    setCityList([])
    setDistrictList([])
    setRegionList([])
  }

  return (
    <>
      <div className="inner-banner-style1 text-center">
        <FilterContent
          country={country}
          city={city}
          district={district}
          region={region}
          countryFetch={countryFetch}
          cityFetch={cityFetch}
          districtFetch={districtFetch}
          setRegion={setRegion}
          cityList={cityList}
          districtList={districtList}
          regionList={regionList}
          handleSubmit={handleSubmit}
        />
      </div>
      <div className="advance-feature-modal">
        <div
          className="modal fade"
          id="advanceSeachModal"
          tabIndex={999}
          aria-labelledby="advanceSeachModalLabel"
          aria-hidden="true"
        >
          <AdvanceFilterModal 
            propertyId={propertyId}
            setPropertyId={setPropertyId}
            status={status}
            setStatus={setStatus}
            price={price}
            setPrice={setPrice}
            bedrooms={bedrooms}
            setBedrooms={setBedrooms}
            bathrooms={bathrooms}
            setBathrooms={setBathrooms}
            propertyType={propertyType}
            setPropertyType={setPropertyType}
            handleSubmit={handleSubmit}
            onReset={onReset}
          />
        </div>
      </div>
    </>
  );
};

export default Hero;
