import DefaultHeader from "../../components/header/DefaultHeader";
import Footer from "../../components/footer";
import MobileMenu from "../../components/mobile-menu";
import PropertyFiltering from "../../components/PropertyFiltering";
import MetaData from "../../components/MetaData";

const DefaultPage = ({ settings, content, path1 }) => {

    const metaInformation = {
        title: settings?.company_name + " | " + content?.title,
    };
  return (
    <>
    <MetaData meta={metaInformation} />
      <DefaultHeader settings={settings} />

      <MobileMenu settings={settings} />

      <section className="breadcumb-section2 p-0" style={{ backgroundImage: `url(${content?.image})` }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcumb-style1">
                <h2 className="title">{content?.title}</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our-about pb90">
        <div className="container">
          {content?.advert == 1 &&
            <div className="mb10">
              <PropertyFiltering content={content} path1={path1} />
            </div>
          }
          <div dangerouslySetInnerHTML={{ __html: content?.content }} />
        </div>
      </section>

      <section className="footer-style1 at-home4 pt60 pb-0">
        <Footer settings={settings} />
      </section>
    </>
  );
};

export default DefaultPage;
