import React, { useState, useEffect } from "react";
import ListingSidebar from "./form";
import Cookies from 'js-cookie';
import Modal from "react-modal";
import {useLocale} from "../context/locale";

import { useLocation, useNavigate } from "react-router-dom";

export default function PropertyFiltering({ content, path1 }) {
  const { messages, locale } = useLocale();
  let lang = '';

  if (locale != 'en') {
    lang = '/' + locale;
  }
  const currentPath = window.location.pathname;
  const navigate = useNavigate();
  const [ data, setData ] = useState(null)
  const [ colstyle, setColstyle ] = useState(false);
  const [ page, setPage ] = useState(1)
  const [ cityList, setCityList ] = useState([])
  const [ districtList, setDistrictList ] = useState([])
  const [ regionList, setRegionList ] = useState([])
  const [ search, setSearch ] = useState({
    country: '',
    city: '',
    district: '',
    region: '',
    propertyId: '',
    status: '',
    propertyType: '',
    price: '',
    bedrooms: '',
    bathrooms: ''
  })
  const location = useLocation()
  const [ isReady, setIsReady ] = useState(false)
  const searchParams = new URLSearchParams(location.search);
  const [ country, setCountry ] = useState('')
  const [ city, setCity ] = useState('')
  const [ district, setDistrict ] = useState('')
  const [ region, setRegion ] = useState('')
  const [ propertyId, setPropertyId ] = useState(null)
  const [ status, setStatus ] = useState()
  const [ propertyType, setPropertyType ] = useState('')
  const [ price, setPrice ] = useState({ min: 20, max: 10000000 })
  const [ bedrooms, setBedrooms ] = useState(0)
  const [ bathrooms, setBathrooms ] = useState(0)
  const [ sort, setSort ] = useState('newest')

  const [ modalIsOpen, setModalIsOpen ] = useState(false)
  const [ currentListing, setCurrentListing ] = useState(null)

  const countryFetch = async (id) => {
    setCity(null)
    setCityList([])
    setDistrict(null)
    setDistrictList([])
    setRegion(null)
    setRegionList([])
    setCountry(id)
    const response = await axios.post('/api/city', { country_id: id });
    const data = response.data.city;

    setCityList([
      ...data.map((value) => ({ label: value.title, value: value.id }))
    ])
  }

  const cityFetch = async (id) => {
    setDistrict(null)
    setDistrictList([])
    setRegion(null)
    setRegionList([])
    setCity(id)
    const response = await axios.post('/api/district', { city_id: id });
    const data = response.data.district;

    setDistrictList([
      ...data.map((value) => ({ label: value.title, value: value.id }))
    ])
  }

  const districtFetch = async (id) => {
    setRegion(null)
    setRegionList([])
    setDistrict(id)
    const response = await axios.post('/api/neighborhood', { district_id: id });
    const data = response.data.neighborhood;

    setRegionList([
      ...data.map((value) => ({ label: value.title, value: value.id }))
    ])
  }

  const searchData = () => {
    const queryParams = new URLSearchParams({
      country: country || '',
      city: city || '',
      district: district || '',
      region: region || '',
      propertyId: propertyId || '',
      status: status || '',
      propertyType: propertyType || '',
      min: price.min,
      max: price.max,
      bedrooms: bedrooms || 0,
      bathrooms: bathrooms || 0
    }).toString();

    window.location.href = `${currentPath}?${queryParams}`;
  }

  const onreset = () => {
    const queryParams = new URLSearchParams({
      country: '',
      city: '',
      district: '',
      region: '',
      propertyId: '',
      status: '',
      propertyType: '',
      price: '',
      bedrooms: '',
      bathrooms: ''
    }).toString();
    window.location.href = `${currentPath}?${queryParams}`;
  }

  const openModal = (listing) => {
    setCurrentListing(listing);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const compare = async(id) => {
    try {
      const response = await axios.post('/api/compare/add', { id });

      if (response.data.success && response.data.id) {
        const existingItems = Cookies.get('compare');
        const itemsArray = existingItems ? JSON.parse(existingItems) : [];

        if (!itemsArray.includes(response.data.id)) {
          if (itemsArray.length < 3) {
            itemsArray.push(response.data.id);
            Cookies.set('compare', JSON.stringify(itemsArray), { expires: 7 });
            window.location.reload()
          }
        }
      }
      else {

      }
    } catch (error) {
      console.error('Error in compare function:', error);
    }
  }

  useEffect(() => {
    if (searchParams.get('country') || searchParams.get('city') || searchParams.get('district') || searchParams.get('bedrooms') ||
    searchParams.get('region') || searchParams.get('propertyId') || searchParams.get('min') || searchParams.get('max') ||
    searchParams.get('bathrooms')) {
      setSearch({
        country: Number(searchParams.get('country')),
        city: Number(searchParams.get('city')),
        district: Number(searchParams.get('district')),
        region: Number(searchParams.get('region')),
        propertyId: searchParams.get('propertyId'),
        status: searchParams.get('status'),
        propertyType: searchParams.get('propertyType'),
        price: {
          min: Number(searchParams.get('min')) || 20,
          max: Number(searchParams.get('max')) || 10000000
        },
        bedrooms: searchParams.get('bedrooms'),
        bathrooms: searchParams.get('bathrooms')
      });
      countryFetch(Number(searchParams.get('country')));
      cityFetch(Number(searchParams.get('city')));
      districtFetch(Number(searchParams.get('district')));
      setRegion(Number(searchParams.get('region')));
      setStatus(searchParams.get('status'));
      setPropertyId(searchParams.get('propertyId'));
      setPropertyType(searchParams.get('propertyType') ? searchParams.get('propertyType').split(',') : [])
      setBedrooms(Number(searchParams.get('bedrooms')) || 0);
      setBathrooms(Number(searchParams.get('bathrooms')) || 0);
      setPrice({
          min: Number(searchParams.get('min')) || 20,
          max: Number(searchParams.get('max')) || 10000000
      })
    } else {
      const data = Array.isArray(content?.advert_type) ? content.advert_type.map((value) => value.name) : [];
      setSearch({
        country: content?.country,
        city: content?.city,
        district: content?.district,
        region: content?.region,
        propertyId: content?.propertyId,
        status: '',
        propertyType: data,
        bedrooms: content?.bedrooms,
        bathrooms: content?.bathrooms
      });
      countryFetch(content?.country);
      cityFetch(content?.city);
      districtFetch(content?.district);
      setRegion(content?.region);
      setStatus(content?.advert_statu);
      setPropertyId(content?.propertyId);
      setBedrooms(content?.bedrooms || 0);
      setBathrooms(content?.bathrooms || 0);
      setPropertyType(data)
    }
    setIsReady(true);
  }, []);

  useEffect(() => {
    if (isReady) {
      const filteredAdvert = async () => {
        try {
          const response = await axios.post('/api/advert', {
           locale: locale,
            page: page,
            propertyType: search.propertyType,
            country: search.country,
            city: search.city,
            district: search.district,
            region: search.region,
            propertyId: search.propertyId,
            status: search.status,
            price: search.price,
            bedrooms: search.bedrooms,
            bathrooms: search.bathrooms,
            sort: sort
          });
          setData(response.data.advert);
        } catch (error) {
          console.log(error);
        }
      };
      filteredAdvert();
    }
  }, [isReady, page, search, sort])

  const changeSort = (sort) => {
    setSort(sort)
    setPage(1)
  }

  return (

    <div className="container">
        <div className="row">
          <div className="col-md-2 mb30">
              <a
                className="mobile-filter-btn d-block d-lg-none mt15"
                data-bs-toggle="offcanvas"
                href="#listingSidebarFilter"
                role="button"
                aria-controls="listingSidebarFilter"
              >
                <span className="flaticon-settings" /> Filter
              </a>
            </div>
        </div>
        <div className="row gx-xl-5">
          <div className="col-lg-4 d-none d-lg-block">
            <ListingSidebar
              searchData={searchData}
              onreset={onreset}
              country={country}
              city={city}
              district={district}
              region={region}
              propertyId={propertyId}
              status={status}
              propertyType={propertyType}
              price={price}
              bedrooms={bedrooms}
              bathrooms={bathrooms}
              setCountry={setCountry}
              setCity={setCity}
              setDistrict={setDistrict}
              setRegion={setRegion}
              setPropertyId={setPropertyId}
              setStatus={setStatus}
              setPropertyType={setPropertyType}
              setPrice={setPrice}
              setBedrooms={setBedrooms}
              setBathrooms={setBathrooms}
              countryFetch={countryFetch}
              cityFetch={cityFetch}
              districtFetch={districtFetch}
              cityList={cityList}
              setCityList={setCityList}
              districtList={districtList}
              setDistrictList={setDistrictList}
              regionList={regionList}
              setRegionList={setRegionList}
            />
          </div>
          <div
              className="offcanvas offcanvas-start p-0"
            tabIndex="-1"
            id="listingSidebarFilter"
            aria-labelledby="listingSidebarFilterLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="listingSidebarFilterLabel">
                Listing Filter
              </h5>
              <button
                type="button"
                  className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body p-0">
              <ListingSidebar
                searchData={searchData}
                onreset={onreset}
                country={country}
                city={city}
                district={district}
                region={region}
                propertyId={propertyId}
                status={status}
                propertyType={propertyType}
                price={price}
                bedrooms={bedrooms}
                bathrooms={bathrooms}
                setCountry={setCountry}
                setCity={setCity}
                setDistrict={setDistrict}
                setRegion={setRegion}
                setPropertyId={setPropertyId}
                setStatus={setStatus}
                setPropertyType={setPropertyType}
                setPrice={setPrice}
                setBedrooms={setBedrooms}
                setBathrooms={setBathrooms}
                countryFetch={countryFetch}
                cityFetch={cityFetch}
                districtFetch={districtFetch}
                cityList={cityList}
                setCityList={setCityList}
                districtList={districtList}
                setDistrictList={setDistrictList}
                regionList={regionList}
                setRegionList={setRegionList}
              />
            </div>
          </div>

          <div className="col-lg-8">
            <div className="row align-items-center mb20">
            <div className="col-sm-6">
              <div className="text-center text-sm-start">
                <p className="pagination_page_count mb-0">
                    {messages?.properties_filter?.showing} {page * 10 - 10} - {page * 10}
                </p>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="page_control_shorting d-flex align-items-center justify-content-center justify-content-sm-end">
                <div className="pcs_dropdown pr10 d-flex align-items-center">
                  <span style={{ minWidth: "60px" }}>{messages?.properties_filter?.sort_by}</span>
                  <select className="form-select" onChange={(e) => changeSort(e.target.value)}>
                    <option value="newest">{messages?.properties_filter?.newest}</option>
                    <option value="oldest">{messages?.properties_filter?.oldest}</option>
                    <option value="pricelow">{messages?.properties_filter?.price_low}</option>
                    <option value="pricehigh">{messages?.properties_filter?.price_high}</option>
                  </select>
                </div>
                <div className={`pl15 pr15 bdrl1 bdrr1 d-none d-md-block cursor  ${!colstyle? 'menuActive':'#' } `}  onClick={()=>setColstyle(false)}>
                  Grid
                </div>
                <div className={`pl15 d-none d-md-block cursor  ${colstyle? 'menuActive':'#' }`}   onClick={()=>setColstyle(true)}>
                  List
                </div>
              </div>
            </div>
            </div>

            <div className="row mt15">
            {data?.data?.map((listing) => (
              <div className={` ${colstyle ? 'col-sm-12':'col-sm-6 col-lg-6'}  `} key={listing.id}>
                <div className={colstyle ? "listing-style1 listCustom listing-type" : "listing-style1"}>
                  <div className="list-thumb" >
                    <img
                      style={{height:'230px'}}
                      className="w-100 cover"
                      src={listing.image}
                      alt={listing.title_seo}
                    />

                    <div className="list-price">
                      {listing.formatted_converted_price}
                    </div>
                  </div>
                  <div className="list-content">
                    <h6 className="list-title">
                      <a href={`${lang}/properties/${listing.link}`}>{listing.title}</a>
                    </h6>
                    <p className="list-text">
                      {listing.region != null && listing.region?.title + " / "}
                      {listing.district != null && listing.district?.title + " / "}
                      {listing.city != null && listing.city?.title + " / "}
                      {listing.country != null && listing.country?.title}
                    </p>
                    <div className="list-meta d-flex align-items-center">
                      <a href="#">
                        <span className="flaticon-bed" /> {listing.number_of_beds != null && listing.number_of_beds} {messages?.properties?.bed}
                      </a>
                      <a href="#">
                        <span className="flaticon-shower" /> {listing.number_of_bathrooms != null && listing.number_of_bathrooms} {messages?.properties?.bath}
                      </a>
                      <a href="#">
                        <span className="flaticon-expand" /> {listing.square_meters != null && listing.square_meters} {messages?.properties?.sqft}
                      </a>
                    </div>
                    <hr className="mt-2 mb-2" />
                    <div className="list-meta2 d-flex justify-content-between align-items-center">
                      <span className="for-what">{listing?.real_estate_status}</span>
                      <div className="icons d-flex align-items-center">
                        <a href={'/properties/' + listing.link}>
                          <span className="flaticon-fullscreen" />
                        </a>
                        <a onClick={() => compare(listing.id)}>
                          <span className="flaticon-new-tab" />
                        </a>
                        <a onClick={() => openModal(listing)}>
                          <span className="flaticon-share-1" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            </div>

            <div className="row">
              <div className="mbp_pagination text-center">
                <ul className="page_navigation">
                <li className="page-item">
                  <span
                    className="page-link pointer"
                    href="#"
                  >
                    <span className="fas fa-angle-left" />
                  </span>
                </li>
                {data?.links?.map((link, index) =>
                  <React.Fragment key={index}>
                    {index != 0 && index + 1 != data?.links.length ?
                      <li
                        onClick={() => setPage(link.label)}
                        className={link.active ? "active page-item" : "page-item"}
                      >
                        <span className="page-link pointer">{link.label}</span>
                      </li>
                    : ''}
                  </React.Fragment>

                  )}
                  <li className="page-item pointer">
                    <span
                      className="page-link"
                      href="#"
                    >
                      <span className="fas fa-angle-right" />
                    </span>
                  </li>
                </ul>
                <p className="mt10 pagination_page_count text-center">
                </p>
              </div>
            </div>
          </div>
        </div>
        {currentListing && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Share Modal"
          ariaHideApp={false}
          style={{
            overlay: {
              zIndex: 1000,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              width: '40%',
              padding: '30px',
              position: 'relative',
            },
          }}
        >
          <button onClick={closeModal} style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'none',
            border: 'none',
            fontSize: '18px',
            cursor: 'pointer',
          }}>
            ✖
          </button>
          <div className="share-box">
            <div className="social-items">
              <div className="social-item">
                <a href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="Facebook">
                  <i className="fa-brands fa-facebook-f"></i>
                </a>
              </div>
              <div className="social-item">
                <a href={`https://twitter.com/intent/tweet?url=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="Twitter X">
                  <i className="fa-brands fa-twitter"></i>
                </a>
              </div>
              <div className="social-item">
                <a href={`https://www.linkedin.com/shareArticle?url=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="LinkedIn">
                  <i className="fa-brands fa-linkedin-in"></i>
                </a>
              </div>
              <div className="social-item">
                <a href={`https://pinterest.com/pin/create/button/?url=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="Pinterest">
                  <i className="fa-brands fa-pinterest-p"></i>
                </a>
              </div>
              <div className="social-item">
                <a href={`whatsapp://send?text=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="WhatsApp">
                  <i className="fa-brands fa-whatsapp"></i>
                </a>
              </div>
              <div className="social-item">
                <a href={`https://www.reddit.com/submit?url=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="Reddit">
                  <i className="fa-brands fa-reddit"></i>
                </a>
              </div>
              <div className="social-item">
                <a href={`https://t.me/share/url?url=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="Telegram">
                  <i className="fa-brands fa-telegram"></i>
                </a>
              </div>
              <div className="social-item">
                <a href={`https://www.tumblr.com/widgets/share/tool/preview?url=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="Tumblr">
                  <i className="fa-brands fa-tumblr"></i>
                </a>
              </div>
              <div className="social-item">
                <a href={`mailto:?body=${window.location.origin}${lang}/properties/${currentListing.link}`} target="_blank" title="Email">
                  <i className="fa-regular fa-envelope"></i>
                </a>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
