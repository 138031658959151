
import React, { useState } from "react";
import {useNavigate} from 'react-router-dom';
import "react-input-range/lib/css/index.css";
import Country from "./Country";
import City from "./City";
import District from "./District";
import Region from "./Region";
import {useLocale} from "../../context/locale";

const FilterContent = ({ country, city, district, region, countryFetch, cityFetch, districtFetch, cityList, districtList, regionList, setRegion, handleSubmit }) => {
  const navigate = useNavigate();
  const { messages } = useLocale();

  return (
    <div className="advance-style4 at-home5 mt-100 mt50-lg mb10 mx-auto animate-up-2">

      <div className="tab-content text-start">
        <div className="advance-content-style3 at-home5">
          <div className="row align-items-center">

            <div className="col-md-4 col-xl-2 bdrr1 bdrrn-sm px20 pl15-sm">
              <div className="mt-3 mt-md-0 px-0">
                <div className="bootselect-multiselect">
                  <label className="fz14">{messages?.home?.country}</label>
                    <Country country={country} setCountry={countryFetch} />
                </div>
              </div>
            </div>

            <div className="col-md-4 col-xl-2 bdrr1 bdrrn-sm px20 pl15-sm">
              <div className="mt-3 mt-md-0">
                <div className="bootselect-multiselect">
                  <label className="fz14">{messages?.home?.city}</label>
                  <City city={city} setCity={cityFetch} cityList={cityList} />
                </div>
              </div>
            </div>

            <div className="col-md-4 col-xl-2 bdrr1 bdrrn-sm px20 pl15-sm">
              <div className="mt-3 mt-md-0">
                <div className="bootselect-multiselect">
                  <label className="fz14">{messages?.home?.district}</label>
                  <District district={district} setDistrict={districtFetch} districtList={districtList} />
                </div>
              </div>
            </div>

            <div className="col-md-4 col-xl-2 bdrr1 bdrrn-sm px20 pl15-sm">
              <div className="mt-3 mt-md-0">
                <div className="bootselect-multiselect">
                  <label className="fz14">{messages?.home?.region}</label>
                  <Region region={region} setRegion={setRegion} regionList={regionList} />
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="d-flex align-items-center justify-content-start justify-content-md-center mt-3 mt-md-0">
                <button
                  className="advance-search-btn"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#advanceSeachModal"
                >
                  <span className="flaticon-settings" /> {messages?.home?.advanced}</button>
                <button
                  className="advance-search-icon ud-btn btn-thm ms-4"
                  type="button"
                  onClick={() => handleSubmit()}
                >
                  <span className="flaticon-search" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterContent;
