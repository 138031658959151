import { useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { useLocale } from "../context/locale";
import axios from "axios";

const MainMenu = () => {
  const { locale } = useLocale();
  const { path1, path2, path3, path4 } = useParams();
  let p1, p2, p3;

  if (locale !== "en") {
    p1 = path2;
    p2 = path3;
    p3 = path4;
  } else {
    p1 = path1;
    p2 = path2;
    p3 = path3;
  }

  const [category, setCategory] = useState([]);
  const [megaMenuContent, setMegaMenu] = useState();
  const [megaMenuTitle, setMegaTitle] = useState();
  const [activeMenu, setActiveMenu] = useState(null);
  const menuRef = useRef(null);

  useEffect(() => {
    const getCategory = async () => {
      try {
        const categoryResponse = await axios.post("/api/category", {
          locale: locale,
        });
        setCategory(categoryResponse.data.category);

        const initialCategory = categoryResponse.data.category.find((cat) => cat.type === 3);
        if (initialCategory?.subcategory?.length > 0) {
          setMegaTitle(initialCategory.subcategory[0]);
          setMegaMenu(initialCategory.subcategory[0].megacategory);
          setActiveMenu(initialCategory.id);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getCategory();
  }, [locale]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setActiveMenu(null);
        setMegaMenu(null);
        setMegaTitle(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleMenuClick = (row, index) => {
    if (activeMenu === index) {
      setActiveMenu(null);
      setMegaMenu(null);
      setMegaTitle(null);
    } else {
      setActiveMenu(index);
      if (row.type === 3 && row.subcategory?.length > 0) {
        setMegaTitle(row.subcategory[0]);
        setMegaMenu(row.subcategory[0].megacategory);
      } else {
        setMegaMenu(null);
        setMegaTitle(null);
      }
    }
  };

  return (
    <ul className="ace-responsive-menu" ref={menuRef}>
      {category?.map((row, index) => (
        <React.Fragment key={index}>
          {row.type === 1 && (
            <li className="visible_list dropitem">
              <a className="list-item" href={`/${locale !== 'en' ? locale + '/' + row.url : row.url}`}>
                <span className={`${p1 === row.url || (p1 === null && row.url === '') ? "title menuActive" : "title"}`}>
                  {row.name}
                </span>
              </a>
            </li>
          )}
          {row.type === 2 && (
              <li className="visible_list">
                <a
                  className="list-item"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveMenu(activeMenu === index ? null : index);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <span className={`${p1 === row.url ? "title menuActive" : "title"}`}>
                    {row.name}
                  </span>
                  <span className="arrow"></span>
                </a>
                {activeMenu === index && (
                  <ul className="sub-menu"
                    style={{
                      opacity: "1",
                      visibility: "visible",
                      transform: "none",
                    }}>
                    {row.subcategory?.map((sub, subIndex) => (
                      <li key={subIndex} className="dropitem">
                        <a
                          href={`/${locale !== 'en' ? locale + '/' + row.url + '/' + sub.url : row.url + '/' + sub.url}`}
                        >
                          <span className={p1 === row.url && p2 === sub.url ? "title menuActive" : "title"}>
                            {sub.name}
                          </span>
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            )}

          {row.type === 3 && (
            <li className="megamenu_style dropitem">
              <a
                className="list-item"
                onClick={() => handleMenuClick(row, index)}
                style={{ cursor: "pointer" }}
              >
                <span className={p1 === row.url ? "title menuActive" : "title"}>
                  {row.name}
                </span>
                <span className="arrow"></span>
              </a>
              {activeMenu === index && (
                <div className="megaMenuCustomContainer active">
                  <ul className="megaMenuCustomNav">
                    {row.subcategory?.map((sub, subIndex) => (
                      <li
                        key={subIndex}
                        onClick={() => {
                          setMegaTitle(sub);
                          setMegaMenu(sub.megacategory);
                        }}
                        style={{background: megaMenuTitle === sub ? "#d4b8dc" : "transparent"}}
                      >
                        {sub.name}
                      </li>
                    ))}
                  </ul>
                  <div className="megaMenuCustomContent">
                    <a href={`/${locale !== 'en' ? locale + '/' + row.url + '/' + megaMenuTitle?.url : row.url + '/' + megaMenuTitle?.url}`}>
                      {megaMenuTitle?.name}
                    </a>
                    <ul style={row.id === 4 ? { display: "flex", flexDirection: "column" } : {}}>
                      {megaMenuContent?.map((item, itemIndex) => (
                        <li key={itemIndex}>
                          <a
                            href={`/${
                              locale !== 'en'
                                ? locale + '/' + row.url + '/' + megaMenuTitle.url + '/' + item.url
                                : row.url + '/' + megaMenuTitle.url + '/' + item.url
                            }`}
                            className={p1 === row.url && p2 === item.url ? "title menuActive" : "title"}>
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </li>
          )}
        </React.Fragment>
      ))}
    </ul>
  );
};

export default MainMenu;
