import React from "react";
import {useLocale} from "../../context/locale";

const ContactMeta = ({ settings }) => {
  const { messages } = useLocale();
  const contactInfoList = [
    {
      title: messages?.footer?.total_free_customer_care,
      phone: settings?.phone,
      phoneLink: "tel:" + settings?.phone,
    },
    {
      title: messages?.footer?.need_live_support,
      mail: settings?.email,
      mailLink: "mailto:" + settings?.email,
    },
  ];

  return (
    <div className="row mb-4">
      {contactInfoList.map((contact, index) => (
        <div className="col-auto" key={index}>
          <div className="contact-info">
            <p className="text">{contact.title}</p>
            {contact.phone && (
              <h6 className="info-phone">
                <a href={contact.phoneLink}>{contact.phone}</a>
              </h6>
            )}
            {contact.mail && (
              <h6 className="info-mail">
                <a href={contact.mailLink}>{contact.mail}</a>
              </h6>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ContactMeta;
