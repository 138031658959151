import React from 'react'
import BlogFilter from './BlogFilter'
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLocale } from "../../context/locale";

export default function BlogFilterContainer({ content }) {
    const { locale } = useLocale()
    const { path1, path2, path3 } = useParams()
    const [ page, setPage ] = useState(1)
    const [ blogs, setBlogs ] = useState(null);
    const [ categoryList, setCategoryList ] = useState(null)
    const [ category, setCategory ] = useState(null)

    const updateCategory = (category) => {
      setPage(1)
      setCategory(category)
    }

    useEffect(() => {
      if(path1 != undefined && path2 != undefined) {
        setCategory(content?.category_id)
      }
    },[])

    useEffect(() => {
      const getBlogs = async() => {
          try{
              const response = await axios.post('/api/blogs', {
                locale: locale,
                page: page,
                category: category
              })
              setBlogs(response.data.blogs)
              setCategoryList(response.data.category)
          }
          catch(error) {
              console.log(error)
          }
      }
      getBlogs()
  },[page, category])

  return (
    <section className="our-blog pt-0">
    <div className="container">
      <div className="row" data-aos="fade-up" data-aos-delay="300">
        <div className="col-xl-12 navpill-style1">
          <BlogFilter blogs={blogs?.data} categoryList={categoryList} category={category} updateCategory={updateCategory} />
        </div>
      </div>

      <div className="row">
        <div className="mbp_pagination text-center">
        <ul className="page_navigation mt20">
          <li className="page-item" key="previous">
            <button
              className="page-link"
            >
              <span className="fas fa-angle-left" />
            </button>
          </li>
          {blogs?.links?.map((link, index) =>
            <React.Fragment key={index}>
              {index != 0 && index + 1 != blogs?.links.length ?
                <li
                  className={`page-item ${link.active ? "active" : ""}`}
                  aria-current={link.active ? "page" : undefined}
                  key={page}
                >
                  <button className="page-link" onClick={() => setPage(link.label)}>
                    {link.label}
                  </button>
                </li>
              : ''}
            </React.Fragment>
          )}
          <li className="page-item" key="next">
            <button
              className="page-link"
            >
              <span className="fas fa-angle-right" />
            </button>
          </li>
        </ul>

        </div>
      </div>
    </div>
  </section>
  )
}
