import React from "react";

const Blog = ({ relatedPost }) => {
  return (
    <>
      {relatedPost?.map((blog, index) => (
        <div className="col-sm-6 col-lg-4" key={index}>
          <div className="blog-style1">
            <div className="blog-img">
              <img
               
                className="w-100 h-100 cover"
                src={blog.image}
                alt={blog.tags}
              />
            </div>
            <div className="blog-content">
              <h6 className="title mt-1">
                  <a href={`/${blog?.category?.url}/${blog.link}`}>{blog.title}</a>
              </h6>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Blog;
